import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as fp from 'lodash/fp';
import { TMessageUUID } from '../../model/message/Message';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { MessageContext } from '../../model/message/MessageContext';
import { KeyboardContext } from './KeyboardContext';
import $ from 'jquery';
import { reactSpringPortal } from '../../lib/HTMLComp';

export type TAbsoluteOrigin = { x: number; y: number };

const chatViewAnimationOriginId = `message-animation-origin-box`;

const somethingContextValue = {
  animatingMessageIds: {} as Record<TMessageUUID, boolean>,
  setAnimateMessageById: null as any,
  onAnimateMessageByIdComplete: null as any,
  animationOrigin: {
    x: 0,
    y: 0,
  } as TAbsoluteOrigin,
  chatViewAnimationOriginId,
  chatListScroller: null as (null | ReturnType<typeof useScrollOnAddMessage>),
};

export const ChatViewMessageBoxAnimationContext = React.createContext({
  ...somethingContextValue,
  chatViewMessageBoxAnimationContextRef: null as any as React.MutableRefObject<typeof somethingContextValue>,
});

type TChatViewMessageBoxAnimationContextProps = {
  //
};

export const ChatViewMessageBoxAnimationContextProvider = ({ children }: PropsWithChildren<TChatViewMessageBoxAnimationContextProps>) => {
  const [animatingMessageIds, setAnimatingMessageIds] = React.useState<Record<TMessageUUID, boolean>>(somethingContextValue.animatingMessageIds);
  const [animationOrigin, setAnimationOrigin] = React.useState(somethingContextValue.animationOrigin);

  const defaultAnimationOriginRef = React.useRef<TAbsoluteOrigin>(somethingContextValue.animationOrigin);
  React.useLayoutEffect(() => {
    const origin = document.getElementById(chatViewAnimationOriginId);
    const originRect = origin?.getBoundingClientRect();
    if (originRect) {
      const origin = {
        x: originRect.x,
        y: originRect.y - originRect.height,
      };
      defaultAnimationOriginRef.current = origin;
      setAnimationOrigin(origin);
    }
  }, []);

  const setAnimateMessageById = React.useCallback((messageId: TMessageUUID, origin?: TAbsoluteOrigin) => {
    setAnimationOrigin(origin ?? defaultAnimationOriginRef.current);
    setAnimatingMessageIds((ids) => fp.set(messageId, true, ids));
  }, [setAnimatingMessageIds, setAnimationOrigin, defaultAnimationOriginRef]);

  const onAnimateMessageByIdComplete = React.useCallback((messageId: TMessageUUID) => {
    setAnimatingMessageIds((ids) => fp.set(messageId, false, ids));
  }, [setAnimatingMessageIds]);

  const chatListScroller = useScrollOnAddMessage();
  const chatViewMessageBoxAnimationContextValue = {
    animatingMessageIds,
    setAnimateMessageById,
    onAnimateMessageByIdComplete,
    animationOrigin,
    chatViewAnimationOriginId,
    chatListScroller,
  };

  const chatViewMessageBoxAnimationContextRef = useSyncedDataRef(chatViewMessageBoxAnimationContextValue);

  const { keyboardOpen } = React.useContext(KeyboardContext);
  React.useEffect(() => {
    $(reactSpringPortal).css({
      visibility: keyboardOpen ? 'hidden' : 'visible',
      overflow: keyboardOpen ? 'hidden' : 'visible',
    });
  }, [keyboardOpen]);

  return (
    <ChatViewMessageBoxAnimationContext.Provider
      value={{
        ...chatViewMessageBoxAnimationContextValue,
        chatViewMessageBoxAnimationContextRef,
      }}
    >
      {children}
    </ChatViewMessageBoxAnimationContext.Provider>
  );
};

function useScrollOnAddMessage() {
  const { displayMessages } = React.useContext(MessageContext);
  const { keyboardOpen, keyboardContextRef } = React.useContext(KeyboardContext);
  const scrollableRefInternal = React.createRef<HTMLDivElement>();
  const scrollableRefExternal = React.createRef<HTMLDivElement>();

  const scrollToEnd = React.useCallback(() => {
    const scrollableRef = keyboardContextRef.current.keyboardOpen
      ? scrollableRefExternal
      : scrollableRefInternal;

    scrollableRef.current?.scrollTo({
      behavior: 'smooth',
      top: scrollableRef.current?.scrollHeight,
    });
  }, [keyboardContextRef, scrollableRefExternal, scrollableRefInternal]);

  React.useEffect(() => {
    scrollToEnd();
  }, [scrollToEnd, displayMessages.length]);

  React.useEffect(() => {
    if (keyboardOpen) {
      scrollToEnd();
    }
  }, [keyboardOpen, scrollToEnd]);

  return {
    scrollableRefInternal,
    scrollableRefExternal,
    scrollToEnd,
  };
}
