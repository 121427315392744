import * as React from 'react';
import { PropsWithChildren } from 'react';
import { EMessageType } from '../../model/message/Message';
import { MessageContext } from '../../model/message/MessageContext';
import { VisitorContext } from '../../model/user/VisitorContext';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { DialogFlowContext } from '../../model/dialogFlow/DialogFlowContext';
import { LocaleContext } from '../../model/locale/LocaleContext';
import { ChatViewMessageBoxAnimationContext, TAbsoluteOrigin } from './ChatViewMessageBoxAnimationContext';
import { useSystemUser } from '../../model/user/useUsers';
import { Config } from '../../config/Config';
import { Log } from '../../instance/Log';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

const audio = new Audio('./send.mp3');

const chatContextValue = {
  onSend: (() => undefined) as ((input: string, animationOrigin?: TAbsoluteOrigin) => void),
  introMessageSent: false as boolean,
};

export const ChatContext = React.createContext({
  ...chatContextValue,
  chatContextRef: null as any as React.MutableRefObject<typeof chatContextValue>,
});

type TChatContextProviderProps = {
  //
};

export const ChatContextProvider = ({ children }: PropsWithChildren<TChatContextProviderProps>) => {
  const { visitorContextRef } = React.useContext(VisitorContext);

  const { messageContextRef } = React.useContext(MessageContext);

  const { dialogFlowContextRef } = React.useContext(DialogFlowContext);

  const { chatViewMessageBoxAnimationContextRef } = React.useContext(ChatViewMessageBoxAnimationContext);

  const userSystem = useSystemUser();

  const onSend = React.useCallback(async (text: string, animationOrigin?: TAbsoluteOrigin) => {
    const visitorMessageId = Utils.uuidv4();

    // Before adding visitors message set that it will animate, this prevents
    // the flicker caused by starting the animation after having added the message
    chatViewMessageBoxAnimationContextRef.current.setAnimateMessageById(visitorMessageId, animationOrigin);

    if (dialogFlowContextRef.current.dialogFlowActions.soundIsOn) {
      audio.play();
    }

    // Add users message
    messageContextRef.current.setMessage(visitorMessageId, (message) => ({
      ...message,
      user: visitorContextRef.current.visitor,
      value: {
        type: EMessageType.Text,
        text,
      },
    }));

    // Add backend typing message
    const backendResponseMessageId = Utils.uuidv4();
    messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
      ...message,
      user: userSystem,
      value: { type: EMessageType.NoDisplay },
    }));

    try {
      const {
        result,
        onPostProcess,
      } = await Utils.minDelayCallback(Config.messagePreBackendResponseDelayMs, async () => {
        const [result] = await Promise.all([
          // Start the request immediately
          dialogFlowContextRef.current.onSendText({ text }),

          // Wait for a minimum time and post typing message
          new Promise<void>((resolve) => {
            setTimeout(() => {
              messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
                ...message,
                user: userSystem,
                value: { type: EMessageType.Typing },
              }));
              resolve();
            }, Config.messagePreBackendTypingDelayMs);
          }),
        ]);

        return result;
      });

      // Post response
      messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
        ...message,
        ...result,
      }));

      try {
        await onPostProcess();
      } catch (e) {
        Log.e('ChatContext', 'onSend', `onPostProcess failed`, (e as any)?.message);
      }
    } catch (e) {
      setTimeout(() => {
        // Clear backend message so we don't type forever
        messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
          ...message,
          value: { type: EMessageType.NoDisplay },
        }));
      }, Config.messagePreBackendTypingDelayMs + 300);
    }
  }, [userSystem, messageContextRef, dialogFlowContextRef, visitorContextRef, chatViewMessageBoxAnimationContextRef]);

  const introMessageSent = usePlayIntroOnMount();

  const value = {
    onSend,
    introMessageSent,
  };

  const chatContextRef = useSyncedDataRef(value);
  return (
    <ChatContext.Provider
      value={{
        ...value,
        chatContextRef,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function usePlayIntroOnMount() {
  const { messageContextRef } = React.useContext(MessageContext);
  const { localeContextRef } = React.useContext(LocaleContext);

  const [introMessageSent, setIntroMessageSent] = React.useState(false);
  const setIntroMessageSentRef = useSyncedDataRef(setIntroMessageSent);

  React.useEffect(() => {
    (async () => {
      messageContextRef.current.setMessage(Utils.uuidv4(), (message) => ({
        ...message,
        value: {
          type: EMessageType.Text,
          text: localeContextRef.current.strings.messageTextOwnerFirstMessage,
        },
      }));
      setIntroMessageSentRef.current(true);
    })();
  }, [localeContextRef, messageContextRef, setIntroMessageSentRef]);

  return introMessageSent;
}
