import { Intents } from './DialogFlowActions';
import * as React from 'react';
import { VisitorContext } from '../user/VisitorContext';
import { MessageContext } from '../message/MessageContext';
import { useMakeAnonymousVisitorUser } from '../user/useUsers';
import * as _ from 'lodash';
import { messageIsFromVisitor } from '../message/Message';
import { DialogFlow, TDialogFlowQueryResponseResult } from './DialogFlow';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

/**
 * If isNameChangeIntent or is nameUserDelete intent then update the visitors name
 */
export const useDialogFlowName = () => {
  const { visitorContextRef } = React.useContext(VisitorContext);
  const { messageContextRef } = React.useContext(MessageContext);

  const makeAnonymousVisitorUser = useMakeAnonymousVisitorUser();

  const handle = React.useCallback((result: TDialogFlowQueryResponseResult) => {
    function updateUsername(name: string) {
      visitorContextRef.current.setVisitor((visitor) => ({
        ...visitor,
        name,
      }));

      // Update old messages
      messageContextRef.current.setMessages((messages) => {
        return _.mapValues(messages, (message) => {
          if (!messageIsFromVisitor(message)) {
            return message;
          }

          return {
            ...message,
            user: {
              ...message.user,
              name,
            },
          };
        });
      });
    }

    if (DialogFlow.isIntent(result, Intents.nameUserDelete)) {
      return updateUsername(makeAnonymousVisitorUser().name);
    }

    const isNameChangeIntent = DialogFlow.isIntent(result, [
      Intents.nameUserConfirm,
      Intents.nameUserSave,
      Intents.nameUserConfirm,
      Intents.nameUserChange,
    ]);

    const personName = DialogFlow.getParameterSysPersonName(result);
    if (isNameChangeIntent && personName) {
      return updateUsername(Utils.titleCase(personName));
    }
  }, [visitorContextRef, makeAnonymousVisitorUser]);

  return { handle };
};
