import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef } from '../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

import { Config } from '../config/Config';

export type TTheme = typeof Config.themes.start;

export type TWithTheme<T = {}> = T & {
  theme: TTheme;
};

const themeContextValue = {
  theme: Config.themes.start,
  setTheme: (() => undefined) as (theme: TTheme) => void,
};

export const ThemeContext = React.createContext({
  ...themeContextValue,
  themeContextRef: null as any as React.MutableRefObject<typeof themeContextValue>,
});

type TThemeContextProps = {
  //
};

export const ThemeContextProvider = ({ children }: PropsWithChildren<TThemeContextProps>) => {
  const [theme, setTheme] = React.useState(themeContextValue.theme);

  const muiTheme = React.useMemo(() => {
    return createTheme({
      palette: {
        primary: { main: theme.colors.primaryText },
        secondary: { main: theme.colors.secondaryText },
        success: { main: theme.colors.positive },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
    });
  }, [theme]);

  const themeContextRef = useSyncedDataRef({
    theme,
    setTheme,
  });

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        themeContextRef,
      }}
    >
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

declare module 'styled-components' {
  // @ts-ignore
  export type DefaultTheme = TTheme;
}
