import * as React from 'react';
import { LocaleContext } from '../locale/LocaleContext';
import { ESender, TUser } from './User';
import { Strings } from '../locale/Strings';
import { localeDefault } from '../locale/ELocale';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

export function useSystemUser(): TUser {
  return React.useMemo(() => ({
    id: `user-system`.toLowerCase(),
    name: Strings[localeDefault].userSystem,
    locale: localeDefault,
    type: ESender.System,
  }), []);
}

export function useMakeAnonymousVisitorUser() {
  const { strings } = React.useContext(LocaleContext);
  return React.useCallback(() => ({
    id: `user-${Utils.uuidv4()}`.toLowerCase(),
    name: strings.userAnonymousVisitorName,
    locale: strings.locale,
    type: ESender.Visitor,
  }), [strings]);
}
