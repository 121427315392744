import * as _ from 'lodash';
import { Log } from './instance/Log';

function titleCase(str: string) {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const base64ArrayBuffer = (function () {
  /* eslint-disable */
  // Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
  // use window.btoa' step. According to my tests, this appears to be a faster approach:
  // http://jsperf.com/encoding-xhr-image-data/5

  /*
  MIT LICENSE
  Copyright 2011 Jon Leighton
  Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
  The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
  */
  return function (arrayBuffer: any) {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes = new Uint8Array(arrayBuffer);
    const { byteLength } = bytes;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;

    let a;
    let b;
    let c;
    let
      d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i += 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += `${encodings[a] + encodings[b]}==`;
    } else if (byteRemainder == 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += `${encodings[a] + encodings[b] + encodings[c]}=`;
    }

    return base64;
  };
  /* eslint-enable */
}());

function deepMerge<T, X>(t: T, x: X): T & X {
  return _.mergeWith(_.cloneDeep(t), _.cloneDeep(x), (objValue, srcValue) => {
    return _.isArray(objValue)
      ? objValue.concat(srcValue)
      : undefined;
  });
}

function memoizeFunc<T extends (...any: any[]) => any>(func: T) {
  const cache = {};
  return function (...args: any[]) {
    const argsStr = JSON.stringify(args);

    // @ts-ignore
    cache[argsStr] = cache[argsStr] || func.apply(this, args);
    // @ts-ignore
    return cache[argsStr];
  } as any as T;
}

function isNotEmptyString(value: any): value is string {
  return !_.isEmpty(value);
}

function randomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function safe<T>(
  run: () => T,
  errFn: ((err: Error) => T | undefined) | undefined = undefined,
): T | undefined {
  try {
    return run();
  } catch (e) {
    // @ts-ignore
    return errFn && errFn(e);
  }
}

async function safeWait<T>(
  run: () => Promise<T>,
  errFn: ((err: Error) => T),
): Promise<T> {
  try {
    return await run();
  } catch (e) {
    // @ts-ignore
    return errFn(e);
  }
}

async function safeWaitOrUndefined<T>(
  run: () => Promise<T>,
  errFn?: ((err: Error) => T),
): Promise<T | undefined> {
  try {
    return await run();
  } catch (e) {
    return errFn != null
      // @ts-ignore
      ? errFn(e)
      : undefined;
  }
}

async function safeWaitOrError<T>(
  run: () => Promise<T>,
  errFn?: ((err: Error) => T),
): Promise<T | Error> {
  try {
    return await run();
  } catch (e) {
    const err = e instanceof Error
      ? e
      : new Error(`Utils, safeWaitOrError, error ${e}`);

    return errFn != null
      ? errFn(err)
      : err;
  }
}

async function safeWaitOrMap<T>(
  run: () => Promise<T>,
  map: ((err: Error) => Error),
): Promise<T> {
  try {
    return await run();
  } catch (e) {
    // @ts-ignore
    throw map(e);
  }
}

async function consumePagination<Response, T>(
  run: (nextPageToken: string | undefined) => Promise<Response>,
  getData: (response: Response) => T[],
  getNextPageToken: (response: Response) => string | undefined,
): Promise<T[]> {
  const data: T[] = [];

  let nextPageToken: string | undefined = undefined;
  do {
    const response = await run(nextPageToken);
    pushAll(data, getData(response));
    nextPageToken = getNextPageToken(response);
  } while (nextPageToken != null);

  return data;
}

function pushAll<T>(dest: T[], src: T[]) {
  src.forEach((val) => dest.push(val));
  return dest;
}

function push(arr: any[], element: any) {
  arr.push(element);
  return arr;
}

function fpPush(arr: any[] | undefined, element: any) {
  return push(_.cloneDeep(arr || []), element);
}

function fpRemove<T>(arr: T[], index: number): T[] {
  const dup = _.cloneDeep(arr);
  index > -1 && dup.splice(index, 1);
  return dup;
}

function rotate<T>(array: T[], start: T): T[] {
  const res = [_.findIndex(array, (item) => item == start)];
  for (let i = 0; i < array.length - 1; i++) {
    const num = _.last(res) as any as number;
    res.push((num + 1) % array.length);
  }
  return res
    .map((idx) => array[idx]);
}

async function reducePromises(arr: (() => Promise<any>)[]): Promise<any> {
  await arr.reduce(async (previousPromise, promiseBuilder) => {
    await previousPromise;
    await promiseBuilder();
  }, Promise.resolve());
}

function uuidv4() {
  /* eslint-disable */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  /* eslint-enable */
}

function md5(inputString: string) {
  var hc = '0123456789abcdef';

  function rh(n) {
    var j, s = '';
    for (j = 0; j <= 3; j++) s += hc.charAt((n >> (j * 8 + 4)) & 0x0F) + hc.charAt((n >> (j * 8)) & 0x0F);
    return s;
  }

  function ad(x, y) {
    var l = (x & 0xFFFF) + (y & 0xFFFF);
    var m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xFFFF);
  }

  function rl(n, c) {
    return (n << c) | (n >>> (32 - c));
  }

  function cm(q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }

  function ff(a, b, c, d, x, s, t) {
    return cm((b & c) | ((~b) & d), a, b, x, s, t);
  }

  function gg(a, b, c, d, x, s, t) {
    return cm((b & d) | (c & (~d)), a, b, x, s, t);
  }

  function hh(a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }

  function ii(a, b, c, d, x, s, t) {
    return cm(c ^ (b | (~d)), a, b, x, s, t);
  }

  function sb(x) {
    var i;
    var nblk = ((x.length + 8) >> 6) + 1;
    var blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) blks[i] = 0;
    for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }

  var i, x = sb(inputString), a = 1732584193, b = -271733879, c = -1732584194, d = 271733878, olda, oldb, oldc, oldd;
  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;
    a = ff(a, b, c, d, x[i + 0], 7, -680876936);
    d = ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = ff(c, d, a, b, x[i + 10], 17, -42063);
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = gg(b, c, d, a, x[i + 0], 20, -373897302);
    a = gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = hh(a, b, c, d, x[i + 5], 4, -378558);
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = hh(d, a, b, c, x[i + 0], 11, -358537222);
    c = hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = ii(a, b, c, d, x[i + 0], 6, -198630844);
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = ad(a, olda);
    b = ad(b, oldb);
    c = ad(c, oldc);
    d = ad(d, oldd);
  }
  return rh(a) + rh(b) + rh(c) + rh(d);
}

function addIfDefined<Key extends string, Val, TBD = any>(
  propName: Key,
  tbd: TBD | undefined,
  value?: (tbd: TBD) => Val,
): { [key in Key]?: Val } {
  if (tbd != null) {
    const res = value != null
      ? value(tbd)
      : tbd;
    return { [propName]: res } as any;
  }

  return {};
}

function addIfDefinedArr<Val>(
  toBeDefined: any,
  value: Val = toBeDefined,
): [] | [Val] {
  return toBeDefined != null
    ? [value]
    : [] as any;
}

function addIfTrueArr<Val>(
  toBeTrue: boolean,
  value: Val,
): [] | [Val] {
  return addIfDefinedArr(toBeTrue || undefined, value);
}

function addIfTrue<Key extends string, Truthy extends boolean, Val>(
  propName: Key,
  toBeTrue: Truthy,
  value: Val,
): Truthy extends true ? { [key in Key]: Val } : { [key in Key]: Val } {
  return addIfDefined<Key, Val>(propName, !toBeTrue && undefined, () => value) as any;
}

function removeEmpty(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    // @ts-ignore
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
}

function makeSearch(obj: any) {
  return Object.keys(obj ?? {})
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
}

function orderedClamp(val: number, min: number, max: number) {
  const realMin = min < max ? min : max;
  const realMax = max > min ? max : min;
  return _.clamp(val, realMin, realMax);
}

function filterNull<T>(items: (T | null | undefined)[]): T[] {
  // @ts-ignore
  return items.filter((item) => item != null);
}

function ifTrueStub<T extends (...any: any[]) => any>(val: boolean, func: T): T {
  return val
    ? ((...any: any[]) => undefined) as T
    : func;
}

function delay(timeMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, timeMs));
}

async function minDelay<T>(minDelayMs: number, promise: Promise<T>): Promise<T> {
  const delayPromise = await delay(minDelayMs);
  const [, result] = await Promise.all([delayPromise, promise]);
  return result;
}

async function minDelayCallback<T>(minDelayMs: number, run: () => Promise<T>) {
  const delayPromise = await delay(minDelayMs);
  const [, result] = await Promise.all([delayPromise, run()]);
  return result;
}

function resolveWithTimeout<T>(waitMs: number, result?: T): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(result as any), waitMs);
  });
}

function randomInt(min, max) {
  return Math.floor(Math.random() * max + 1);
}

function mapRange(num: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

function promisify(result): Promise<void> {
  return new Promise((resolve) => resolve(result));
}

function parseIntOrUndefined(str: string | undefined): number | undefined {
  return parseIntOrDefault(str, undefined);
}

function parseIntOrDefault<T>(str: string | undefined, def: T): number | T {
  const int = parseInt(`${str}`, 10);
  if (Number.isNaN(int)) {
    return def;
  }
  return int;
}

function parseIntOrThrow<T>(str: string | undefined): number {
  const int = parseInt(`${str}`, 10);
  if (Number.isNaN(int)) {
    throw new Error(['HelperFunctions', 'parseIntOrThrow', `Invalid Int ${str}`].join(', '));
  }
  return int;
}

function parseBoolOrThrow<T>(str: string | undefined): boolean {
  const boolOrUndefined = parseBoolOrUndefined(`${str}`);
  if (boolOrUndefined == null) {
    throw new Error(['HelperFunctions', 'parseIntOrThrow', `Invalid bool ${str}`].join(', '));
  }
  return boolOrUndefined;
}

function parseFloatOrDefault<T>(str: string | undefined, def: T): number | T {
  const float = parseFloat(`${str}`);
  if (Number.isNaN(float)) {
    return def;
  }
  return float;
}

function parseBoolOrUndefined(str: string | undefined): boolean | undefined {
  return parseBoolOrDefault(str, undefined);
}

function parseBoolOrDefault<T>(_str: string | undefined, def: T): boolean | T {
  const str = `${_str}`.toLowerCase();
  return str !== 'true' && str !== 'false'
    ? def
    : str === 'true';
}

function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

function parseJsonOrDefault<T>(str: string, def: T | undefined = undefined): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return def;
  }
}

function boolToInt(bool: boolean) {
  return bool ? 1 : 0;
}

function mutateRemoveAtIndex<T>(arr: T[], index: number): T[] {
  index > -1 && arr.splice(index, 1);
  return arr;
}

function deleteProps(obj: any, ...propNames: string[]) {
  const duplicate = { ...obj };
  propNames.forEach((propName) => {
    delete duplicate[propName];
  });
  return duplicate;
}

function runnerOrDefault<T>(value: T | undefined, defaultVal: T): T {
  return value || defaultVal;
}

function toggleValueInDataByKey<V>(val: V, _data: { [k: string]: V } | undefined, key: keyof V): { [k: string]: V } {
  const data = _data || {};
  const found = _.findKey(_.values(data), (v: V) => {
    return v[key] === val[key];
  });
  return found
    ? { ..._.omit(data, val[key] as any) }
    : { ..._.set(data, val[key] as any, val) };
}

const mapAndFilterNull = (() => {
  type TFO<T> = {
    [key: string]: T;
  };

  return function <T, R1 = T>(
    obj: TFO<T> = {},
    map: (val: T) => R1 | undefined,
  ): TFO<R1> {
    return _.reduce(obj, (acc, item, key) => {
      const mapped = map(item);
      return mapped != null
        ? _.set(acc, key, mapped)
        : acc;
    }, {} as TFO<R1>);
  };
})();

const filterObj = (() => {
  type TFO<T> = {
    [key: string]: T;
  };

  return function <T>(
    obj: TFO<T> = {},
    filter: (val: T) => boolean,
  ): TFO<T> {
    return _.reduce(obj, (acc, item, key) => {
      const addToResult = filter(item);
      return addToResult
        ? _.set(acc, key, item)
        : acc;
    }, {} as TFO<T>);
  };
})();

function objToUrlParams(obj: any): string {
  const url = Object.keys(obj).reduce((acc, key) => {
    return obj[key] != null && obj[key] !== ''
      ? `${acc}&${key}=${obj[key]}`
      : acc;
  }, '');
  return url.startsWith('&')
    ? url.substring(1)
    : url;
}

function urlParamsToObj(params: string): any {
  if (params == null || `${params}`.length <= 0) {
    return {};
  }

  return (params || '')
    .split('&')
    .reduce((acc, p) => {
      const [key, val] = p.split('=');
      acc[key] = val;
      return acc;
    }, {});
}

function cachedValue<T>(generate: () => T, lazy = false) {
  let val: T | null = null;
  let generated = false;

  function get(): T {
    if (!generated) {
      val = generate();
      generated = true;
    }
    return val as any;
  }

  if (!lazy) {
    get();
  }

  return { get };
}

const cachedValueByKey = (() => {
  const cache = {
    //
  };

  return function <T>(key: string, generate: () => T) {
    if (cache[key] == null) {
      cache[key] = generate();
    }

    return cache[key] as T;
  };
})();

function strHashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(int: number) {
  // eslint-disable-next-line
  const c = (int & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length) + c}`;
}

function isBoolean(val: any): val is boolean {
  return _.isBoolean(val);
}

function deterministicHex(seed: string) {
  return intToRGB(strHashCode(seed));
}

function valuesOfObjSortedByKeys(obj: any): any[] {
  return _.chain(obj)
    .keys()
    .sort()
    .map((key) => obj[key])
    .value();
}

function joinArrBy<T, X>(arr: T[], getJoiner: (idx: number) => X) {
  return arr.reduce((acc, el, index) => {
    return index !== arr.length - 1
      ? [...acc, el, getJoiner(index)]
      : [...acc, el];
  }, [] as (T | X)[]);
}

function joinArrMap<T, X>(arr: T[], mapper: (el: T, idx: number) => X, getJoiner: (idx: number) => X): X[] {
  return joinArrBy<T, X>(arr, getJoiner)
    .map((el, index) => (index % 2 === 0 ? mapper(el as T, index) : el as X));
}

function enumToArray<E>(enumme: any): E[] {
  return Object.keys(enumme)
    // Take all keys which are not numbers
    .filter((key) => Number.isNaN(parseFloat(key)))
    // Map to values
    .map((key) => enumme[key]);
}

function selectNotEmpty<T>(def: T, ...selectors: (T | undefined | null)[]) {
  for (let i = 0; i < selectors.length; i++) {
    if (!_.isEmpty(selectors[i])) {
      return selectors[i];
    }
  }
  return def;
}

function isNotNull(value: any) {
  return value != null;
}

function isNotEmpty<T>(value: T | undefined | null): value is T {
  return !_.isEmpty(value);
}

export async function addAndMap<K extends string, T, R = T>(
  key: K,
  data?: T,
  map?: (t: T) => Promise<R> | R,
): Promise<{ [k: string]: R | T }> {
  if (data == null) {
    return {};
  }

  const value = map != null
    ? await map(data)
    : data;

  return { [key]: value };
}

function sortObj<T>(unordered: T): T {
  return Object.keys(unordered).sort().reduce(
    (obj, key) => {
      obj[key] = unordered[key];
      return obj;
    },
    {},
  ) as T;
}

function truncate(input: string, max: number) {
  if (input.length > max) {
    return `${input.substring(0, max)}...`;
  }
  return input;
}

function partitionByComparison<T>(_arr: T[], same: (comp1: T, comp2: T) => boolean): T[][] {
  // Duplicate to avoid mutations
  const arr = [..._arr];

  const result: T[][] = [];
  for (let i = 0; i < arr.length; i++) {
    const groupLead = arr[i];
    if (!groupLead) {
      continue;
    }

    const group = [groupLead];
    for (let x = i + 1; x < arr.length; x++) {
      if (arr[x] == null) {
        // If arr[x] is null then this element was already put into a previous group
        continue;
      }

      if (!same(groupLead, arr[x])) {
        continue;
      }

      // Items are the same, add to group and pin as undefined
      // so the i loop knows to skip this item as a groupLead
      group.push(arr[x]);

      // @ts-ignore
      arr[x] = undefined;
    }

    result.push(group);
  }

  return result;
}

function cleanObj(object: any) {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        cleanObj(v);
      }
      if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
        if (Array.isArray(object)) {
          object.splice(k as any, 1);
        } else {
          delete object[k];
        }
      }
    });
  return object;
}

function parseCookie(cookie: string): any {
  return Object.fromEntries((cookie || '').split('; ').map((x) => x.split('=')));
}

function buildMaxAttemptsFunction<T extends (...any) => Promise<any>>(maxAttempts: number, resetIntervalMs: number, func: T): T {
  let attempt = 0;
  return (async (...params: Parameters<T>) => {
    try {
      if (attempt >= maxAttempts) {
        setTimeout(() => {
          Log.e('Utils', 'buildMaxAttemptsFunction', `[attempt=${attempt}] Resetting attempts`);
          attempt = 0;
        }, resetIntervalMs);
        return undefined;
      }
      return await func(...params);
    } catch (e) {
      Log.e('Utils', 'buildMaxAttemptsFunction', `[attempt=${attempt}] ${e.message}`);
      attempt++;
      throw e;
    }
  }) as T;
}

function addMemoizedGetter<Key extends string, Val>(key: Key, build: () => Val) {
  let val: any = null;
  let initalized = false;
  return {
    get [key]() {
      if (!initalized) {
        val = build();
        initalized = true;
      }
      return val;
    },
  } as { [Key: string]: Val };
}

function memoizeAsync<T extends (...any) => Promise<any>>(func: T) {
  const cache = {};
  return async function (...args: any[]) {
    const argsStr = JSON.stringify(args);

    // @ts-ignore
    cache[argsStr] = cache[argsStr] || func.apply(this, args);
    return cache[argsStr];
  } as any as T;
}

function setIfPropertyNotEmpty<T, V = T>(
  setter: (val: V) => void,
  toBeNotEmpty: T | undefined,
  mapper: (val: T) => V = _.identity,
) {
  if (!_.isEmpty(toBeNotEmpty)) {
    // @ts-ignore
    setter(mapper(toBeNotEmpty));
  }
}

function makeReffable<T>(obj: T): T & { ref: { current: T } } {
  return {
    ...obj,
    ref: { current: obj },
  };
}

function padStr(str: string, len: number): string {
  return str.substr(0, len).padEnd(len, ' ');
}

export const Utils = {
  base64ArrayBuffer,
  deepMerge,
  memoizeFunc,
  isNotEmptyString,
  randomString,
  safe,
  safeWait,
  safeWaitOrUndefined,
  safeWaitOrError,
  safeWaitOrMap,
  consumePagination,
  pushAll,
  push,
  fpPush,
  fpRemove,
  rotate,
  reducePromises,
  uuidv4,
  md5,
  addIfDefined,
  addIfDefinedArr,
  addIfTrueArr,
  addIfTrue,
  removeEmpty,
  makeSearch,
  orderedClamp,
  filterNull,
  ifTrueStub,
  delay,
  minDelay,
  minDelayCallback,
  resolveWithTimeout,
  randomInt,
  mapRange,
  promisify,
  parseIntOrUndefined,
  parseIntOrDefault,
  parseIntOrThrow,
  parseBoolOrThrow,
  parseFloatOrDefault,
  parseBoolOrUndefined,
  parseBoolOrDefault,
  hexToRGB,
  parseJsonOrDefault,
  boolToInt,
  mutateRemoveAtIndex,
  deleteProps,
  runnerOrDefault,
  toggleValueInDataByKey,
  mapAndFilterNull,
  objToUrlParams,
  urlParamsToObj,
  cachedValue,
  cachedValueByKey,
  strHashCode,
  intToRGB,
  isBoolean,
  deterministicHex,
  valuesOfObjSortedByKeys,
  joinArrBy,
  joinArrMap,
  enumToArray,
  selectNotEmpty,
  isNotNull,
  isNotEmpty,
  addAndMap,
  sortObj,
  truncate,
  partitionByComparison,
  cleanObj,
  parseCookie,
  buildMaxAttemptsFunction,
  addMemoizedGetter,
  memoizeAsync,
  setIfPropertyNotEmpty,
  makeReffable,
  padStr,
  titleCase,
};
