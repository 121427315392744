import * as React from 'react';
import styled from 'styled-components';
import { messageIsNotFromVisitor, TMessage } from '../../model/message/Message';
import { ChatViewMessageBoxAnimationContext } from './ChatViewMessageBoxAnimationContext';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from '../../../../../../mono/packages/lib-react/src/contexts-browser/useMeasure';
import { reactSpringMessagesPortal } from '../../lib/HTMLComp';
import ReactDOM from 'react-dom';
import { ChatViewMessageBox } from './ChatViewMessageBox';

type TChatViewMessageRowProps = {
  message: TMessage;
};

export const ChatViewMessageRow = React.memo(({ message }: TChatViewMessageRowProps) => {
  const {
    animatingMessageIds,
    animationOrigin,
    onAnimateMessageByIdComplete,
  } = React.useContext(ChatViewMessageBoxAnimationContext);

  const {
    measurementRef,
    measurements,
    measureRef,
  } = useMeasure();

  const isAnimating = animatingMessageIds[message.id];
  React.useEffect(() => {
    if (!isAnimating) {
      return;
    }

    const interval = setInterval(() => measureRef.current(), 300);
    return () => {
      clearInterval(interval);
    };
  }, [isAnimating]);

  const animation = useSpring(({
    from: {
      opacity: 0.25,
      x: animationOrigin.x,
      y: animationOrigin.y,
    },
    to: {
      opacity: 1,
      x: measurements?.x ?? animationOrigin.x,
      y: measurements?.y ?? animationOrigin.y,
    },
    onRest: () => {
      onAnimateMessageByIdComplete(message.id);
    },
  }));

  const left = messageIsNotFromVisitor(message);
  return (
    <Root>
      <LeftMessageSpacer left={left}/>
      <ShadowWrapper
        ref={measurementRef}
        visible={!isAnimating}
      >
        <ChatViewMessageBox
          message={message}
          left={left}
        />
      </ShadowWrapper>
      {isAnimating && (
        <>
          {ReactDOM.createPortal(
            <PortalWrapper>
              <animated.div
                style={animation}
              >
                <ChatViewMessageBox
                  message={message}
                  left={left}
                />
              </animated.div>
            </PortalWrapper>,
            reactSpringMessagesPortal,
          )}
        </>
      )}
    </Root>
  );
}, (previous, next) => {
  return previous.message.id != next.message.id;
});

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: calc(86.3px + (2vh * 2));
  padding: 12px 0;
`;

export const LeftMessageSpacer = styled.div<{ left: boolean }>`
  ${({ left }) => (left ? '' : 'flex-grow: 1')};
  min-width: 0;
`;

export const PortalWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
`;

export const ShadowWrapper = styled.div<{ visible: boolean }>`
  position: relative;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
