import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedEnhancedDataRef } from '../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';

const defaultContextValue = {
  chatInput: {
    input: '',
    setInput: (() => undefined) as (value: string) => void,
  } as ReturnType<typeof useChatInput>,
};

export const GlobalContext = React.createContext({
  ...defaultContextValue,
  ref: null as any as React.MutableRefObject<typeof defaultContextValue>,
});

type TContextProps = {
  //
};

export const GlobalContextProvider = ({ children }: PropsWithChildren<TContextProps>) => {
  const chatInput = useChatInput();

  const contextValue = useSyncedEnhancedDataRef({ chatInput });
  return (
    <GlobalContext.Provider
      value={contextValue}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export function useChatInput() {
  const [input, setInput] = React.useState('');

  // Hack because we would have to add redux to access the 'onSend' from ChatContext
  // in useDialogFlowAction (Parameter inputKeySend). To workaround the migration we use this flag
  const [autoSend, setAutoSend] = React.useState(false);

  return {
    input,
    setInput,
    autoSend,
    setAutoSend,
  };
}
