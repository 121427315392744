import * as React from 'react';
import { HTMLProps } from 'react';
import styled from 'styled-components';
import { Element } from 'hast';
import { ImageWithModal } from '../ImageWithModal';
import { ChatConst } from './ChatConst';

type TMessageTypeMarkdownImageProps = HTMLProps<HTMLImageElement> & {
  node: Element;
};

export const MessageTypeMarkdownImage = ({ node, ...otherProps }: TMessageTypeMarkdownImageProps) => {
  return (
    // @ts-ignore
    <Image {...otherProps} />
  );
};

const Image = styled(ImageWithModal)`
  ${ChatConst.messageContentMediaParams};
  margin-top: 24px;
`;
