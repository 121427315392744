import * as _ from 'lodash';
import { Log } from './instance/Log';
import { Process, TProcessEnv } from './Process';
import { Encoders } from './Encoders';

export enum ESecretInspectionLevel {
  /**
   * Does nothing on missing or extra secret
   */
  NONE = 0,
  /**
   * Only logs on missing or extra secret
   */
  LOG = 2,
  /**
   * Throws exception on missing or extra secret
   */
  STRICT = 3,
}

type TSecretParams<T> = {
  packageName: string;
  packageSecretKeys: T;
  secretInspectionLevel: ESecretInspectionLevel;
  preload?: () => void
};

export class Secrets<T> {
  readonly params: TSecretParams<T>;

  private readonly instanceSecretKeys: T;

  private readonly secretInspectionLevel: ESecretInspectionLevel;

  /**
   * Lazy loaded for first load performance optimization
   */
  private cachedSecrets: T | undefined;

  get val(): T {
    if (this.cachedSecrets == null) {
      this.cachedSecrets = this.getSecrets(this.instanceSecretKeys);
    }
    return this.cachedSecrets;
  }

  constructor(params: Partial<TSecretParams<T>>) {
    const defaultInspectionLevel = Process.isProduction(Process.env.NODE_ENV)
      ? ESecretInspectionLevel.STRICT
      : ESecretInspectionLevel.LOG;

    this.params = {
      ...params,
      packageName: params.packageName ?? 'unknown-package',
      packageSecretKeys: params.packageSecretKeys ?? ({} as T),
      secretInspectionLevel: params.secretInspectionLevel ?? defaultInspectionLevel,
    };

    this.instanceSecretKeys = _.cloneDeep(this.params.packageSecretKeys) as T;
    this.secretInspectionLevel = this.params.secretInspectionLevel;
  }

  private getSecrets(expectedSecrets: any): T {
    if (this.params.preload) {
      Log.v('Secrets', 'getSecrets', 'Preload');
      this.params.preload();
    } else {
      Log.v('Secrets', 'getSecrets', `No preload defined in package ${this.params.packageName}`);
    }

    Log.v('Secrets', 'getSecrets', 'Loading secrets from env', ...[
      Process.isDevelopment(Process.env.NODE_ENV) ? <TProcessEnv>{
        NODE_ENV: Process.env.NODE_ENV,
        PROJECT_SECRET_JSON: Process.env.PROJECT_SECRET_JSON,
        COMMIT_HASH: Process.env.COMMIT_HASH,
      } : {},
    ]);
    const {
      decSecrets,
      missingSecrets,
    } = (() => {
      const envSecrets = Process.env.PROJECT_SECRET_JSON;
      if (_.isEmpty(envSecrets)) {
        return {
          decSecrets: {} as T,
          missingSecrets: _.keys(this.instanceSecretKeys),
        };
      }

      const decSecret = Encoders.b64ToClearText(envSecrets) || '';
      const decSecrets = JSON.parse(decSecret) as any as T;
      return {
        decSecrets,
        missingSecrets: [],
      };
    })();

    // Ensure all needed secrets are provided
    const expectedSecretKeys = _.keys(expectedSecrets);
    expectedSecretKeys.forEach((expectedSecretKey) => {
      if (missingSecrets.includes(expectedSecretKey)) {
        this.handleInspection('getSecrets', `Expected missing secret "${expectedSecretKey}"`);
      }
    });

    // Ensure there are no extra secrets
    _.keys(decSecrets)
      .filter((secretKey) => !_.isEmpty(decSecrets[secretKey]))
      .forEach((providedSecretKey) => {
        if (!expectedSecretKeys.includes(providedSecretKey)) {
          this.handleInspection('getSecrets', `Config has more secrets than are needed ${providedSecretKey}`);
          delete decSecrets[providedSecretKey];
        }
      });

    return decSecrets;
  }

  private readonly handleInspection = (funcName: string, ...msgs: string[]) => {
    if (this.secretInspectionLevel >= ESecretInspectionLevel.LOG) {
      Log.wtf('Secrets', funcName, ...msgs);
    }

    if (this.secretInspectionLevel >= ESecretInspectionLevel.STRICT) {
      throw new Error(['SecretKeys.ts', funcName, ...msgs].join(', '));
    }
  };
}
