import { Base64 as JSBase64 } from 'js-base64';
import stringify from 'fast-json-stable-stringify';
import { Utils } from './Utils';

function encodeURIComp(uriComponent: string) {
  return encodeURIComponent(uriComponent);
}

function decodeURIComp(encodedUriComponent: string) {
  return decodeURIComponent(encodedUriComponent);
}

function safeURLEncode(obj: any): string {
  return Utils.safe(
    () => encodeURIComp(b64FromClearText(stringify(obj))),
    () => '',
  ) as string;
}

function safeURLDecode(value: string): any {
  return Utils.safe(
    () => JSON.parse(b64ToClearText(decodeURIComponent(value))),
    () => ({}),
  );
}

function b64FromClearText(clearText: string) {
  return JSBase64.btoa(clearText);
}

function b64ToClearText(b64: string) {
  return JSBase64.atob(b64);
}

const unescape = (function () {
  const hex2 = /^[\da-f]{2}$/i;
  const hex4 = /^[\da-f]{4}$/i;

  return function utils_unescape(string: string) {
    const str = `${string}`;
    let result = '';
    const length = str.length;
    let index = 0;
    let chr;
    let slice;
    while (index < length) {
      chr = str.charAt(index++);
      if (chr === '%') {
        if (str.charAt(index) === 'u') {
          slice = str.slice(index + 1, index + 5);
          if (hex4.test(slice)) {
            result += String.fromCharCode(parseInt(slice, 16));
            index += 5;
            continue;
          }
        } else {
          slice = str.slice(index, index + 2);
          if (hex2.test(slice)) {
            result += String.fromCharCode(parseInt(slice, 16));
            index += 2;
            continue;
          }
        }
      }
      result += chr;
    }

    return result;
  };
}());

const escape = (function () {
  const raw = /[\w*+\-./@]/;

  const hex = function (code: number, length: number) {
    let result = code.toString(16);
    while (result.length < length) result = `0${result}`;
    return result;
  };

  return function utils_escape(string: string) {
    const str = `${string}`;
    let result = '';
    const length = str.length;
    let index = 0;
    let chr;
    let
      code;
    while (index < length) {
      chr = str.charAt(index++);
      if (raw.test(chr)) {
        result += chr;
      } else {
        code = chr.charCodeAt(0);
        if (code < 256) {
          result += `%${hex(code, 2)}`;
        } else {
          result += `%u${hex(code, 4).toUpperCase()}`;
        }
      }
    }
    return result;
  };
}());

export const Encoders = {
  encodeURIComp,
  decodeURIComp,
  safeURLEncode,
  safeURLDecode,
  b64FromClearText,
  b64ToClearText,
  unescape,
  escape,
};
