import * as React from 'react';
import { MessageContext } from '../message/MessageContext';
import { Actions } from './DialogFlowActions';
import { DialogFlow, TDialogFlowQueryResponseResult } from './DialogFlow';
import { LocaleContext } from '../locale/LocaleContext';
import { useToggle } from '../../../../../../mono/packages/lib-react/src/hooks/useToggle';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';
import { ThemeContext } from '../../theme/ThemeContext';
import { Config } from '../../config/Config';
import { GlobalContext } from '../../components/GlobalContext';
import * as _ from 'lodash';
import { EMessageType } from '../message/Message';
import { VisitorContext } from '../user/VisitorContext';
import { languageToLocale } from '../locale/ELocale';

export const useDialogFlowActions = () => {
  const { messageContextRef } = React.useContext(MessageContext);
  const { localeContextRef } = React.useContext(LocaleContext);
  const { visitorContextRef } = React.useContext(VisitorContext);
  const { themeContextRef } = React.useContext(ThemeContext);
  const { ref: globalContextRef } = React.useContext(GlobalContext);

  const [calendarUrl, setCalendarUrl] = React.useState<string | null>(null);
  const [soundIsOn, soundToggle, soundOn, soundOff] = useToggle(true);

  const handleAction = React.useCallback(async (action: URLSearchParams, result?: TDialogFlowQueryResponseResult) => {
    const sound = action.get(Actions.sound);
    if (sound) {
      switch (sound) {
        case 'on':
          soundOn();
          break;
        case 'off':
          soundOff();
          break;
        case 'toggle':
          soundToggle();
          break;
      }
    }

    const openCalendar = action.get(Actions.openCalendar);
    if (openCalendar) {
      switch (openCalendar) {
        case 'tech':
          setCalendarUrl(localeContextRef.current.strings.calendarTech);
          break;
        case 'training':
          setCalendarUrl(localeContextRef.current.strings.calendarTraining);
          break;
      }
    }

    const theme = action.get(Actions.theme);
    if (theme) {
      const themeObj = Config.themes[theme as any];
      if (themeObj) {
        themeContextRef.current.setTheme(themeObj);
      }
    }

    const inputKey = action.get(Actions.inputKey);
    const inputKeySend = action.get(Actions.inputKeySend);
    if (inputKey) {
      const input = localeContextRef.current.strings[inputKey];
      if (!_.isEmpty(input)) {
        globalContextRef.current.chatInput.setInput(input);
        if (inputKeySend !== 'false') {
          globalContextRef.current.chatInput.setAutoSend(true);
        }
      }
    }

    const submitConversation = action.get(Actions.submitConversation);
    if (submitConversation) {
      const backendResponseMessageId = Utils.uuidv4();
      messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
        ...message,
        value: { type: EMessageType.Typing },
      }));

      await Utils.minDelayCallback(Config.messagePreBackendResponseDelayMs, async () => {
        try {
          await DialogFlow.client.mutation('conversation.submit', {
            messages: messageContextRef.current.messages
          });

          messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
            ...message,
            value: {
              type: EMessageType.Text,
              text: localeContextRef.current.strings.messageTextConversationSubmitSuccess,
            },
          }));
        } catch(e) {
          messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
            ...message,
            value: {
              type: EMessageType.Text,
              text: localeContextRef.current.strings.messageTextConversationSubmitFailure,
            },
          }));
        }
      })
    }

    const language = action.get(Actions.language);
    if (language) {
      // Try change language from action
      const actionChangedLanguage = await (async () => {
        const locale = languageToLocale(language);
        if (!locale) {
          return false;
        }

        const backendResponseMessageId = Utils.uuidv4();
        messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
          ...message,
          value: { type: EMessageType.Typing },
        }));

        await Utils.delay(Config.messagePreBackendResponseDelayMs)
        const currentLangText = localeContextRef.current.strings.messageTextLanguageSwitchSuccess
        messageContextRef.current.setMessage(backendResponseMessageId, (message) => ({
          ...message,
          value: {
            type: EMessageType.Text,
            text: currentLangText,
          },
        }));

        visitorContextRef.current.setVisitor((visitor) => ({
          ...visitor,
          locale,
        }));

        return true;
      })()

      if (!actionChangedLanguage) {
        // Try change language from result
        await (async () => {
          const locale = languageToLocale(result?.parameters?.fields[Actions.language]?.stringValue);
          if (!locale) {
            messageContextRef.current.setMessage(Utils.uuidv4(), (message) => ({
              ...message,
              value: {
                type: EMessageType.Text,
                text: localeContextRef.current.strings.messageTextLanguageDontSpeak,
              },
            }));
            return;
          }

          const currentLangText = localeContextRef.current.strings.messageTextLanguageSwitchSuccess
          messageContextRef.current.setMessage(Utils.uuidv4(), (message) => ({
            ...message,
            value: {
              type: EMessageType.Text,
              text: currentLangText,
            },
          }));

          visitorContextRef.current.setVisitor((visitor) => ({
            ...visitor,
            locale,
          }));
        })()
      }

    }

  }, [messageContextRef, localeContextRef, setCalendarUrl, soundToggle, soundOn, soundOff]);


  const handle = React.useCallback(async (result: TDialogFlowQueryResponseResult) => {
    const action = Utils.safe(() => new URLSearchParams(DialogFlow.getAction(result)));
    if (action) {
      await handleAction(action, result);
    }
  }, [messageContextRef, localeContextRef, setCalendarUrl, soundToggle, soundOn, soundOff]);

  return {
    handle,
    handleAction,
    calendarUrl,
    setCalendarUrl,
    soundIsOn,
    soundOn,
    soundOff,
  };
};
