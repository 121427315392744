import * as React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ThemeContext } from '../theme/ThemeContext';
import { LottieClose } from './Lottie';

type TModalContent = {
  trigger: React.ReactNode;
  content: React.ReactNode;
  className?: string;
};

export const ModalContent = ({
  className,
  content,
  trigger,
}: TModalContent) => {
  const [open, setOpen] = React.useState(false);
  const onOpen = React.useCallback(() => setOpen(true), [setOpen]);
  const onClose = React.useCallback(() => setOpen(false), [setOpen]);
  return (
    <Root className={className}>
      <Trigger onClick={onOpen}>
        {trigger}
      </Trigger>
      <ModalContentView
        open={open}
        onClose={onClose}
        content={content}
      />
    </Root>
  );
};

type TModalContentViewProps = {
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
};

export const ModalContentView = ({
  open,
  onClose,
  content,
}: TModalContentViewProps) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <StyledDialogContent>
        <ContentWrapper>
          {content}
        </ContentWrapper>
        <StyledClose onClick={onClose}>
          <LottieClose color={[theme.colors.accent1]}/>
        </StyledClose>
      </StyledDialogContent>
    </StyledDialog>
  );
};

const Root = styled.div`
`;

const Trigger = styled.div`
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8vw;
`;

const ContentWrapper = styled.div`
  margin-top: 30px;
  max-height: 90vh;
  max-width: min(90vw, 90vh);
  overflow-y: scroll;
`;

const StyledClose = styled.div`
  cursor: pointer;
  margin-top: -30px;
  width: 128px;
`;
