import * as React from 'react';
import * as _ from 'lodash';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { TWithTheme } from '../theme/ThemeContext';
import { ChatContext } from './chat/ChatContext';
import { DialogFlowContext } from '../model/dialogFlow/DialogFlowContext';
import { cssBody3 } from './css';
import { TQuickResponse } from '../model/dialogFlow/useDialogFlowQuickResponses';
import { useTextInputFocusHandler } from './chat/useTextInputFocusHandler';
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

type TQuickResponseChipsProps = {
  className?: string;
  setInput: (input: string) => void;
  textInputFocusHandler: ReturnType<typeof useTextInputFocusHandler>;
};

export const QuickResponseChips = ({
  className,
  setInput,
  textInputFocusHandler,
}: TQuickResponseChipsProps) => {
  const { dialogFlowQuickResponses } = React.useContext(DialogFlowContext);
  return (
    <StyledStack
      className={className}
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      spacing={{
        xs: 1,
        sm: 2,
        md: 4,
      }}
    >
      {dialogFlowQuickResponses.quickResponses
        .map((quickResponse) => (
          <QuickResponseChip
            key={quickResponse.label}
            quickResponse={quickResponse}
            setInput={setInput}
            textInputFocusHandler={textInputFocusHandler}
          />
        ))}
    </StyledStack>
  );
};

type TQuickResponseChipProps = {
  quickResponse: TQuickResponse;
  setInput: (input: string) => void;
  textInputFocusHandler: ReturnType<typeof useTextInputFocusHandler>;
};

export const QuickResponseChip = ({
  quickResponse,
  setInput,
  textInputFocusHandler,
}: TQuickResponseChipProps) => {
  const {
    label,
    icon,
  } = quickResponse;
  const { onSend } = React.useContext(ChatContext);

  const onActionSend = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    onSend(label, {
      x: event.clientX,
      y: event.clientY,
    });
    setInput('');
  }, [onSend, label]);

  const onClick = React.useCallback(() => {
    setInput(label);
  }, [setInput, label]);

  const onItemPress = textInputFocusHandler.refocusHoc(onClick);
  const onSendPress = textInputFocusHandler.refocusHoc(onActionSend);
  return (
    <StyledChip
      key={label}
      label={(
        <Label>
          <LabelText>{label}</LabelText>
          <StyledIconButton
            aria-label={`send-${label}`.toLowerCase()}
            color="success"
            sx={{ p: 0 }}
            {...onSendPress as any}
          >
            <SendIcon/>
          </StyledIconButton>
        </Label>
      )}
      color="success"
      variant="outlined"
      {...onItemPress}
      icon={_.isString(icon) ? (
        <StyledIcon>
          {icon}
        </StyledIcon>
      ) : undefined}
    />
  );
};

const StyledStack = styled(Stack)`
  flex-wrap: wrap;
  margin: 24px 0 !important;

  .MuiStack-root {
    margin: 24px 0 !important;
  }
`;

const StyledChip = styled(Chip)`
  ${cssBody3};
  color: ${({ theme }: TWithTheme) => theme.colors.primaryText};
  font-style: italic;

  .MuiChip-label {
    ${cssBody3};
    font-style: italic;
    overflow-wrap: break-word;
    text-overflow: clip;
    white-space: normal;
    word-wrap: break-word;
  }
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const LabelText = styled.div`

`;

const StyledIconButton = styled(IconButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 12px;
`;

const StyledIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 26px;
`;
