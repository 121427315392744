import dot from 'dot-object';
import { ELocale } from './ELocale';

export type TStrings = typeof Strings_EN_US;

const Strings_EN_US = {
  locale: 'en-US',
  userAnonymousVisitorName: 'You',
  userSystem: 'Krishan',
  messageTextGenericSuccess:`**Done!** 🎉`,
  messageTextLanguageSwitchSuccess:`**Certo che possiamo parlare in Italiano!** 🎉`,
  messageTextLanguageDontSpeak:`**Sorry!** I don't speak that language 😞  
[Click here](?language=it) to switch to Italian 🇮🇹  
  `,
  messageTextConversationSubmitSuccess: `**Done!**  
I'll get back to you within 24h! 🎉
  `,
  messageTextConversationSubmitFailure: `**Oops...**   
Looks like that didn't work!

Please [click here](?submitConversation=1) to try again. You can also get in touch at [krishanmarcomadan@gmail.com](mailto:krishanmarcomadan@gmail.com)

  `,
  messageTextOwnerFirstMessage: `
  
Hi! 👋 I'm **Krishan**, a Software Developer based in Brescia. 🇮🇹

I can tell you about:   
👉 [My engineering experience and career](?theme=work&inputKey=aboutWork)   
👉 [My tech side hustles and projects](?theme=projects&inputKey=aboutProjects)  
👉 [My consulting company Vertex Team](?theme=projects&inputKey=aboutVertex)   
👉 You can even [book my calendar](?openCalendar=tech) directly   
  
There are more options [here](?theme=start&inputKey=help) but a great start would be to **send me your name using the chat field below**.
  `,
  messageTextOwnerDefaultMessage: `Sorry i didn't get that 😔
Would you mind rephrasing it?`,
  messageTextInputLabel: `Ask me anything!`,
  socialInstagram: 'https://www.instagram.com/krishanmarco',
  socialLinkedin: 'https://www.linkedin.com/in/krishanmarco',
  socialTelegram: 'https://t.me/krishanmarco',
  socialTwitter: 'https://twitter.com/krishanmarco',
  socialFacebook: 'https://www.facebook.com/krishanmarco',
  socialGithub: 'https://www.github.com/krishanmarco',
  calendarTraining: 'https://calendly.com/krishanmarcomadan/just-a-chat',
  calendarTech: 'https://calendly.com/vertexteam/30min',
  systemFullName: 'Krishan Marco Madan',
  systemFullIntro: 'Senior Software Developer',
  'quickResponses.default[0].icon': '📙',
  'quickResponses.default[0].value': 'What are my options?',
  'quickResponses.default[1].icon': '🇮🇹',
  'quickResponses.default[1].value': 'Can we speak in Italian?',
  'quickResponses.default[2].icon': '📜',
  'quickResponses.default[2].value': 'Can I download your CV?',
  'quickResponses.madideas[0].icon': '📙',
  'quickResponses.madideas[0].value': 'What are my options?',
  'quickResponses.madideas[1].value': `What's à la carte?`,
  'quickResponses.training[0].icon': '📙',
  'quickResponses.training[0].value': 'What are my options?',
  'quickResponses.training[1].icon': '📅',
  'quickResponses.training[1].value': 'Can I book your calendar?',
  help: 'What can I do on your website?',
  aboutWork: 'Tell me about your career',
  aboutProjects: 'Tell me about your personal projects',
  aboutTrainer: 'Tell me about your personal training',
  aboutYou: 'Tell me about you',
  aboutMindTek: 'Tell me more about your work at MindTek',
  aboutRuntastic: 'Tell me more about your work at Runtatstic',
  aboutTimeular: 'Tell me more about your work at Timeular',
  aboutScalingParrots: 'Tell me more about your work with Scaling Parrots',
  aboutMadIdeas: 'Tell me more about your work with MAD IDEAS',
  aboutFithancer: 'Tell me more about the Fithancer app.',
  aboutCatchMe: 'Tell me more about CatchMe',
  aboutBillBit: 'Tell me more about BillBit',
  aboutAgritalychain: 'Tell me more about Agritalychain',
  aboutAlacarte: 'Tell me more about the à la carte platform',
  aboutBasketball: 'I understand you also played Basketball?',
  aboutKickboxing: 'So you also know kickboxing?',
  aboutBodybuilding: 'What about your fitness goals?',
  aboutPersonalTraining: 'You\'re also a Personal Trainer right?',
  aboutChatbot: 'How did you build this chatbot?',
  aboutChatbotStack: 'What stack did you use to build this chatbot?',
  openTrainingCalendar: 'What\'s your personal training schedule like?',
  aboutVertex: 'What\'s Vertex Team?',
  reccomendation: 'Can you send me your runtastic Reccomendation letter?',
};

const Strings_IT_IT: TStrings = {
  locale: 'it-IT',
  userAnonymousVisitorName: 'Tu',
  userSystem: 'Krishan',
  messageTextGenericSuccess:`**Fatto!** 🎉`,
  messageTextLanguageSwitchSuccess:`**Of course we can speak in English!** 🎉`,
  messageTextLanguageDontSpeak:`**Scusa!** Per ora parlo solo Inglese e Italiano 😞  
[Clicca qua](?language=en) per cambiare la lingua in Inglese 🇬🇧  
  `,
  messageTextConversationSubmitSuccess: `**Fatto!**  
Ti ricontatterò entro 24 ore! 🎉
  `,
  messageTextConversationSubmitFailure: `**Oops...**   
Non ha funzionato.

[Clicca qua](?submitConversation=1) per riprovare. Puoi anche contattarmi alla mail [krishanmarcomadan@gmail.com](mailto:krishanmarcomadan@gmail.com)

  `,
  messageTextOwnerFirstMessage: `
  
Ciao! 👋 Sono **Krishan**, uno Sviluppatore Bresciano. 🇮🇹

Di cosa vorresti parlare?     
👉 [Del mio curriculum da sviluppatore](?theme=work&inputKey=aboutWork)   
👉 [Dei miei progetti](?theme=projects&inputKey=aboutProjects)   
👉 [Della mia società di consulenza](?theme=work&inputKey=aboutVertex)   
👉 Puoi anche direttamente [prenotare il mio calendario](?openCalendar=tech)   
  
Ci sono altre opzioni [qua](?theme=start&inputKey=help) ma un buon inizio è quello di **Presentarti nella chat sottostante**.
  `,
  messageTextOwnerDefaultMessage: `Scusa non ho capito 😔
Potresti richiedermelo in un modo diverso?`,
  messageTextInputLabel: `Chiedimi qualunque cosa!`,
  socialInstagram: 'https://www.instagram.com/krishanmarco',
  socialLinkedin: 'https://www.linkedin.com/in/krishanmarco',
  socialTelegram: 'https://t.me/krishanmarco',
  socialTwitter: 'https://twitter.com/krishanmarco',
  socialFacebook: 'https://www.facebook.com/krishanmarco',
  socialGithub: 'https://www.github.com/krishanmarco',
  calendarTraining: 'https://calendly.com/krishanmarcomadan/just-a-chat',
  calendarTech: 'https://calendly.com/vertexteam/30min',
  systemFullName: 'Krishan Marco Madan',
  systemFullIntro: 'Senior Software Developer',
  'quickResponses.default[0].icon': '📙',
  'quickResponses.default[0].value': 'Che opzioni ho?',
  'quickResponses.default[1].icon': '🇬🇧',
  'quickResponses.default[1].value': 'Possiamo parlare in Inglese?',
  'quickResponses.default[2].icon': '📜',
  'quickResponses.default[2].value': 'Dove posso trovare il tuo CV?',
  'quickResponses.madideas[0].icon': '📙',
  'quickResponses.madideas[0].value': 'Che opzioni ho?',
  'quickResponses.madideas[1].value': `Cos'è à la carte?`,
  'quickResponses.training[0].icon': '📙',
  'quickResponses.training[0].value': 'Che opzioni ho?',
  'quickResponses.training[1].icon': '📅',
  'quickResponses.training[1].value': 'Posso prenotare una consulenza?',

  help: 'Cosa posso fare sul tuo sito?',
  aboutWork: 'Parlami della tua carriera',
  aboutProjects: 'Parlami dei tuoi progetti personali',
  aboutTrainer: 'Parlami della tua esperienza da Personal Trainer',
  aboutYou: 'Parlami di te',
  aboutMindTek: 'Parlami di MindTek',
  aboutRuntastic: 'Parlami del tuo lavoro con Runtatstic',
  aboutTimeular: 'Parlami del tuo lavoro con Timeular',
  aboutScalingParrots: 'Parlami di Scaling Parrots',
  aboutMadIdeas: 'Parlami di Mad Ideas',
  aboutFithancer: 'Parlami di Fithancer',
  aboutCatchMe: 'Parlami di CatchMe',
  aboutBillBit: 'Dimmi qualcosa su BillBit',
  aboutAgritalychain: 'Parlami di Agritalychain',
  aboutAlacarte: 'Parlami di à la carte',
  aboutBasketball: 'Hai giocato anche a Basket?',
  aboutKickboxing: 'Quindi fai anche Kickboxing?',
  aboutBodybuilding: 'Quali sono I tuoi obbiettivi Fitness?',
  aboutPersonalTraining: 'Sei anche un Personal Trainer giusto?',
  aboutChatbot: 'Come hai fatto a costruire questo Chatbot?',
  aboutChatbotStack: 'Che stack hai usato per questo Chatbot?',
  openTrainingCalendar: 'Mi daresti la tua agenda da Personal Trainer?',
  aboutVertex: `Cos'e' Vertex Team?`,
  reccomendation: 'Mi invii la tua lettera di Raccomandazione?',
};

export const Strings: { [k in ELocale]: any } = {
  [ELocale['it-IT']]: dot.object(Strings_IT_IT),
  [ELocale['en-US']]: dot.object(Strings_EN_US),
};
