import * as React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { TMessage, TMessageValueMarkdown } from '../../model/message/Message';

import remarkGfm from 'remark-gfm';
// @ts-ignore
import remarkEmoji from '@fec/remark-a11y-emoji';
import { MessageTypeMarkdownLink } from './MessageTypeMarkdownLink';
import { MessageTypeMarkdownImage } from './MessageTypeMarkdownImage';

type TMessageTypeMarkdown = {
  message: TMessage<TMessageValueMarkdown>;
  className?: string;
};

const remarkPlugins = [
  remarkGfm,
  remarkEmoji,
];

const components = {
  a: MessageTypeMarkdownLink,
  img: MessageTypeMarkdownImage,
};

export const MessageTypeMarkdown = ({
  message,
  className,
}: TMessageTypeMarkdown) => {
  return (
    <Root className={className}>
      <ReactMarkdown
        remarkPlugins={remarkPlugins}
        components={components}
      >
        {message.value.text}
      </ReactMarkdown>
    </Root>
  );
};

const Root = styled.div`
  font-size: 14px;
`;
