!(function (e, t) {
  typeof exports == 'object' && typeof module == 'object' ? module.exports = t() : typeof define == 'function' && define.amd ? define('Typewriter', [], t) : typeof exports == 'object' ? exports.Typewriter = t() : e.Typewriter = t();
}(typeof self != 'undefined' ? self : this, (function () {
  return (() => {
    const e = {
      75(e) {
        (function () {
          let t; let n; let r; let o; let a; let
            s;
          typeof performance != 'undefined' && performance !== null && performance.now ? e.exports = function () {
            return performance.now();
          } : typeof process != 'undefined' && process !== null && process.hrtime ? (e.exports = function () {
            return (t() - a) / 1e6;
          }, n = process.hrtime, o = (t = function () {
            let e;
            return 1e9 * (e = n())[0] + e[1];
          })(), s = 1e9 * process.uptime(), a = o - s) : Date.now ? (e.exports = function () {
            return Date.now() - r;
          }, r = Date.now()) : (e.exports = function () {
            return (new Date()).getTime() - r;
          }, r = (new Date()).getTime());
        }).call(this);
      },
      4087: (e, t, n) => {
        for (var r = n(75), o = typeof window == 'undefined' ? n.g : window, a = ['moz', 'webkit'], s = 'AnimationFrame', i = o[`request${s}`], l = o[`cancel${s}`] || o[`cancelRequest${s}`], u = 0; !i && u < a.length; u++) i = o[`${a[u]}Request${s}`], l = o[`${a[u]}Cancel${s}`] || o[`${a[u]}CancelRequest${s}`];
        if (!i || !l) {
          let c = 0; let p = 0; const
            d = [];
          i = function (e) {
            if (d.length === 0) {
              const t = r(); const
                n = Math.max(0, 16.666666666666668 - (t - c));
              c = n + t, setTimeout((function () {
                const e = d.slice(0);
                d.length = 0;
                for (let t = 0; t < e.length; t++) {
                  if (!e[t].cancelled) {
                    try {
                      e[t].callback(c);
                    } catch (e) {
                      setTimeout((function () {
                        throw e;
                      }), 0);
                    }
                  }
                }
              }), Math.round(n));
            }
            return d.push({
              handle: ++p,
              callback: e,
              cancelled: !1,
            }), p;
          }, l = function (e) {
            for (let t = 0; t < d.length; t++) d[t].handle === e && (d[t].cancelled = !0);
          };
        }
        e.exports = function (e) {
          return i.call(o, e);
        }, e.exports.cancel = function () {
          l.apply(o, arguments);
        }, e.exports.polyfill = function (e) {
          e || (e = o), e.requestAnimationFrame = i, e.cancelAnimationFrame = l;
        };
      },
    }; const
      t = {};

    function n(r) {
      const o = t[r];
      if (void 0 !== o) return o.exports;
      const a = t[r] = { exports: {} };
      return e[r].call(a.exports, a, a.exports, n), a.exports;
    }

    n.n = (e) => {
      const t = e && e.__esModule ? () => e.default : () => e;
      return n.d(t, { a: t }), t;
    }, n.d = (e, t) => {
      for (const r in t) {
        n.o(t, r) && !n.o(e, r) && Object.defineProperty(e, r, {
          enumerable: !0,
          get: t[r],
        });
      }
    }, n.g = (function () {
      if (typeof globalThis == 'object') return globalThis;
      try {
        return this || new Function('return this')();
      } catch (e) {
        if (typeof window == 'object') return window;
      }
    }()), n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
    const r = {};
    return (() => {
      n.d(r, { default: () => R });
      const e = n(4087); const
        t = n.n(e);
      const o = function (e) {
        return new RegExp(/<[a-z][\s\S]*>/i).test(e);
      }; const a = function (e) {
        const t = document.createElement('div');
        return t.innerHTML = e, t.childNodes;
      }; const
        s = function (e, t) {
          return Math.floor(Math.random() * (t - e + 1)) + e;
        };
      const i = 'TYPE_CHARACTER';
      const l = 'REMOVE_CHARACTER';
      const u = 'REMOVE_ALL';
      const c = 'REMOVE_LAST_VISIBLE_NODE';
      const p = 'PAUSE_FOR';
      const d = 'CALL_FUNCTION';
      const v = 'ADD_HTML_TAG_ELEMENT';
      const f = 'CHANGE_DELETE_SPEED';
      const h = 'CHANGE_DELAY';
      const m = 'CHANGE_CURSOR';
      const y = 'PASTE_STRING';
      const g = 'CLEAR';
      const b = 'CLEAR_END';
      const N = 'CHANGE_CURSOR_POSITION';
      const E = 'HTML_TAG';
      const w = 'TEXT_NODE';

      function T(e, t) {
        const n = Object.keys(e);
        if (Object.getOwnPropertySymbols) {
          let r = Object.getOwnPropertySymbols(e);
          t && (r = r.filter((function (t) {
            return Object.getOwnPropertyDescriptor(e, t).enumerable;
          }))), n.push.apply(n, r);
        }
        return n;
      }

      function A(e) {
        for (let t = 1; t < arguments.length; t++) {
          var n = arguments[t] != null ? arguments[t] : {};
          t % 2 ? T(Object(n), !0).forEach((function (t) {
            _(e, t, n[t]);
          })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : T(Object(n)).forEach((function (t) {
            Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
          }));
        }
        return e;
      }

      function C(e) {
        return (function (e) {
          if (Array.isArray(e)) return O(e);
        }(e)) || (function (e) {
          if (typeof Symbol != 'undefined' && e[Symbol.iterator] != null || e['@@iterator'] != null) return Array.from(e);
        }(e)) || (function (e, t) {
          if (e) {
            if (typeof e == 'string') return O(e, t);
            let n = Object.prototype.toString.call(e).slice(8, -1);
            return n === 'Object' && e.constructor && (n = e.constructor.name), n === 'Map' || n === 'Set' ? Array.from(e) : n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? O(e, t) : void 0;
          }
        }(e)) || (function () {
          throw new TypeError('Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
        }());
      }

      function O(e, t) {
        (t == null || t > e.length) && (t = e.length);
        for (var n = 0, r = new Array(t); n < t; n++) r[n] = e[n];
        return r;
      }

      function S(e, t) {
        for (let n = 0; n < t.length; n++) {
          const r = t[n];
          r.enumerable = r.enumerable || !1, r.configurable = !0, 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
        }
      }

      function _(e, t, n) {
        return t in e ? Object.defineProperty(e, t, {
          value: n,
          enumerable: !0,
          configurable: !0,
          writable: !0,
        }) : e[t] = n, e;
      }

      const x = (function () {
        function n(r, T) {
          const O = this;
          if ((function (e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
          }(this, n)), _(this, 'state', {
            cursorAnimation: null,
            lastFrameTime: null,
            pauseUntil: null,
            eventQueue: [],
            eventLoop: null,
            eventLoopPaused: !1,
            reverseCalledEvents: [],
            calledEvents: [],
            visibleNodes: [],
            initialOptions: null,
            elements: {
              container: null,
              wrapper: document.createElement('span'),
              cursor: document.createElement('span'),
            },
            lastCharacterTyped: null,
          }), _(this, 'options', {
            strings: null,
            cursor: '|',
            delay: 'natural',
            pauseFor: 1500,
            deleteSpeed: 'natural',
            loop: !1,
            autoStart: !1,
            devMode: !1,
            skipAddStyles: !1,
            wrapperClassName: 'Typewriter__wrapper',
            cursorClassName: 'Typewriter__cursor',
            stringSplitter: null,
            onCreateTextNode: null,
            onRemoveNode: null,
          }), _(this, 'setupWrapperElement', (function () {
            O.state.elements.container && (O.state.elements.wrapper.className = O.options.wrapperClassName, O.state.elements.cursor.className = O.options.cursorClassName, O.state.elements.cursor.innerHTML = O.options.cursor, O.state.elements.container.innerHTML = '', O.state.elements.container.appendChild(O.state.elements.wrapper), O.state.elements.wrapper.appendChild(O.state.elements.cursor), O.state.visibleNodes.push(O.state.elements.cursor));
          })), _(this, 'start', (function () {
            return O.state.eventLoopPaused = !1, O.runEventLoop(), O;
          })), _(this, 'pause', (function () {
            return O.state.eventLoopPaused = !0, O;
          })), _(this, 'stop', (function () {
            return O.state.eventLoop && ((0, e.cancel)(O.state.eventLoop), O.state.eventLoop = null), O;
          })), _(this, 'pauseFor', (function (e) {
            return O.addEventToQueue(p, { ms: e }), O;
          })), _(this, 'typeOutAllStrings', (function () {
            return typeof O.options.strings == 'string' ? (O.typeString(O.options.strings).pauseFor(O.options.pauseFor), O) : (O.options.strings.forEach((function (e) {
              O.typeString(e).pauseFor(O.options.pauseFor).deleteAll(O.options.deleteSpeed);
            })), O);
          })), _(this, 'typeString', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
            if (o(e)) return O.typeOutHTMLString(e, t);
            if (e) {
              const n = O.options || {}; const r = n.stringSplitter; const
                a = typeof r == 'function' ? r(e) : e.split('');
              O.typeCharacters(a, t);
            }
            return O;
          })), _(this, 'pasteString', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
            return e && O.addEventToQueue(y, {
              character: e,
              node: t,
            }), O;
          })), _(this, 'typeOutHTMLString', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null; const
              n = a(e);
            if (n.length > 0) {
              for (let r = 0; r < n.length; r++) {
                const o = n[r]; const
                  s = o.innerHTML;
                o && o.nodeType !== 3 ? (o.innerHTML = '', O.addEventToQueue(v, {
                  node: o,
                  parentNode: t,
                }), O.typeString(s, o)) : o.textContent && O.typeString(o.textContent, t);
              }
            }
            return O;
          })), _(this, 'htmlPasteToNodes', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null; const n = a(e); const
              r = [];
            if (n.length > 0) {
              for (let o = 0; o < n.length; o++) {
                const s = n[o]; const
                  i = s.innerHTML;
                s && s.nodeType !== 3 ? (s.innerHTML = '', r.push({
                  type: E,
                  node: s,
                  parentNode: t,
                }), r.push.apply(r, C(O.htmlPasteToNodes(i, s)))) : s.textContent && r.push.apply(r, C(s.textContent.split('').map((function (e) {
                  return {
                    type: w,
                    node: e,
                    parentNode: t,
                  };
                }))));
              }
            }
            return r;
          })), _(this, 'clear', (function () {
            const e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0;
            const t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
            return O.addEventToQueue(g, {
              amount: e,
              callOnRemove: t,
            }), O;
          })), _(this, 'clearEnd', (function () {
            const e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0;
            const t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
            return O.addEventToQueue(b, {
              amount: e,
              callOnRemove: t,
            }), O;
          })), _(this, 'deleteAll', (function () {
            const e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 'natural';
            return O.addEventToQueue(u, { speed: e }), O;
          })), _(this, 'changeDeleteSpeed', (function (e) {
            if (!e) throw new Error('Must provide new delete speed');
            return O.addEventToQueue(f, { speed: e }), O;
          })), _(this, 'changeDelay', (function (e) {
            if (!e) throw new Error('Must provide new delay');
            return O.addEventToQueue(h, { delay: e }), O;
          })), _(this, 'changeCursor', (function (e) {
            if (!e) throw new Error('Must provide new cursor');
            return O.addEventToQueue(m, { cursor: e }), O;
          })), _(this, 'deleteChars', (function (e) {
            if (!e) throw new Error('Must provide amount of characters to delete');
            for (let t = 0; t < e; t++) O.addEventToQueue(l);
            return O;
          })), _(this, 'callFunction', (function (e, t) {
            if (!e || typeof e != 'function') throw new Error('Callback must be a function');
            return O.addEventToQueue(d, {
              cb: e,
              thisArg: t,
            }), O;
          })), _(this, 'typeCharacters', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
            if (!e || !Array.isArray(e)) throw new Error('Characters must be an array');
            return e.forEach((function (e) {
              O.addEventToQueue(i, {
                character: e,
                node: t,
              });
            })), O;
          })), _(this, 'changeCursorPosition', (function (e) {
            const t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
            return O.addEventToQueue(N, {
              position: e,
              delay: t,
            }), O;
          })), _(this, 'removeCharacters', (function (e) {
            if (!e || !Array.isArray(e)) throw new Error('Characters must be an array');
            return e.forEach((function () {
              O.addEventToQueue(l);
            })), O;
          })), _(this, 'addEventToQueue', (function (e, t) {
            const n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
            return O.addEventToStateProperty(e, t, n, 'eventQueue');
          })), _(this, 'addReverseCalledEvent', (function (e, t) {
            const n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2]; const
              r = O.options.loop;
            return r ? O.addEventToStateProperty(e, t, n, 'reverseCalledEvents') : O;
          })), _(this, 'addEventToStateProperty', (function (e, t) {
            const n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
            const r = arguments.length > 3 ? arguments[3] : void 0; const
              o = {
                eventName: e,
                eventArgs: t || {},
              };
            return O.state[r] = n ? [o].concat(C(O.state[r])) : [].concat(C(O.state[r]), [o]), O;
          })), _(this, 'runEventLoop', (function () {
            O.state.lastFrameTime || (O.state.lastFrameTime = Date.now());
            const e = Date.now(); const
              n = e - O.state.lastFrameTime;
            if (!O.state.eventQueue.length) {
              if (!O.options.loop) return;
              O.state.eventQueue = C(O.state.calledEvents), O.state.calledEvents = [], O.options = A({}, O.state.initialOptions);
            }
            if (O.state.eventLoop = t()(O.runEventLoop), !O.state.eventLoopPaused) {
              if (O.state.pauseUntil) {
                if (e < O.state.pauseUntil) return;
                O.state.pauseUntil = null;
              }
              const r = C(O.state.eventQueue); const o = r.shift(); let
                a = 0;
              if (o.eventName === c || o.eventName === l ? a = O.options.deleteSpeed === 'natural' ? s(40, 80) : O.options.deleteSpeed : o.eventName === i && (a = O.options.delay === 'natural' ? o.eventArgs.character === ' ' ? s(180, 190) : O.state.lastCharacterTyped == o.eventArgs.character ? s(90, 100) : s(120, 170) : O.options.delay), !(n <= a)) {
                const T = o.eventName; const
                  S = o.eventArgs;
                switch (O.logInDevMode({
                  currentEvent: o,
                  state: O.state,
                  delay: a,
                }), T) {
                  case y:
                    var _ = S.character; var x = (S.node, O.htmlPasteToNodes(_)); var
                      R = [];
                    x.forEach((function (e) {
                      const t = e.type === w ? document.createTextNode(e.node) : e.node;
                      e.parentNode ? e.parentNode.appendChild(t) : O.state.elements.wrapper.insertBefore(t, O.state.elements.cursor), R.push({
                        type: e.type,
                        node: t,
                        parentNode: e.parentNode ? e.parentNode : O.state.elements.wrapper,
                      });
                    }));
                    var L = O.state.visibleNodes.indexOf(O.state.elements.cursor); var
                      k = O.state.visibleNodes.length;
                    L > 0 ? O.state.visibleNodes = [].concat(C(O.state.visibleNodes.slice(0, L)), R, C(O.state.visibleNodes.slice(L, k))) : L == 0 && (O.state.visibleNodes = [].concat(R, C(O.state.visibleNodes)));
                    break;
                  case i:
                    var D = S.character; var M = S.node; var P = document.createTextNode(D); var
                      j = P;
                    O.options.onCreateTextNode && typeof O.options.onCreateTextNode == 'function' && (j = O.options.onCreateTextNode(D, P)), j && (M ? M.appendChild(j) : O.state.elements.wrapper.insertBefore(j, O.state.elements.cursor));
                    var Q = O.state.visibleNodes.indexOf(O.state.elements.cursor);
                    O.state.visibleNodes.splice(Q, 0, {
                      type: w,
                      character: D,
                      node: j,
                      parentNode: M || O.state.elements.wrapper,
                    }), O.state.lastCharacterTyped = D;
                    break;
                  case l:
                    r.unshift({
                      eventName: c,
                      eventArgs: {},
                    });
                    break;
                  case p:
                    var F = o.eventArgs.ms;
                    O.state.pauseUntil = Date.now() + parseInt(F);
                    break;
                  case d:
                    var H = o.eventArgs; var I = H.cb; var
                      U = H.thisArg;
                    I.call(U, { elements: O.state.elements });
                    break;
                  case v:
                    var G = o.eventArgs; var q = G.node; var
                      Y = G.parentNode;
                    Y ? Y.appendChild(q) : O.state.elements.wrapper.insertBefore(q, O.state.elements.cursor);
                    var B = O.state.visibleNodes.indexOf(O.state.elements.cursor);
                    O.state.visibleNodes.splice(B, 0, {
                      type: E,
                      node: q,
                      parentNode: Y || O.state.elements.wrapper,
                    });
                    break;
                  case u:
                    O.state.visibleNodes;
                    var V = S.speed; var
                      W = [];
                    typeof V == 'number' && (V <= 0 && O.logInDevMode('If you intend to delete the entire text without noticeable delay take a look at the clear() function'), W.push({
                      eventName: f,
                      eventArgs: {
                        speed: V,
                        temp: !0,
                      },
                    }));
                    for (let z = O.state.visibleNodes.indexOf(O.state.elements.cursor), J = 0; J < z; J++) {
                      W.push({
                        eventName: c,
                        eventArgs: {},
                      });
                    }
                    typeof V == 'number' && W.push({
                      eventName: f,
                      eventArgs: {
                        speed: O.options.deleteSpeed,
                        temp: !0,
                      },
                    }), r.unshift.apply(r, W);
                    break;
                  case c:
                    if (O.state.visibleNodes.length) {
                      const X = O.state.visibleNodes.indexOf(O.state.elements.cursor) - 1;
                      if (X < 0) break;
                      const $ = O.state.visibleNodes.splice(X, 1)[0]; const K = ($.type, $.node); const Z = $.character;
                      const ee = $.parentNode;
                      O.options.onRemoveNode && typeof O.options.onRemoveNode == 'function' && O.options.onRemoveNode({
                        node: K,
                        character: Z,
                      }), K && K.parentNode.removeChild(K), ee && ee != O.state.elements.wrapper && ee.childNodes.length == 0 && r.unshift({
                        eventName: c,
                        eventArgs: {},
                      });
                    }
                    break;
                  case b:
                    var te = S.amount; var ne = S.callOnRemove; var
                      re = C(O.state.visibleNodes);
                    if (re.length > 0) {
                      for (var oe = re.filter((function (e) {
                          return e.type === w;
                        })), ae = te && te > 0 && te < oe.length ? te : oe.length, se = function e(t) {
                          if (t != O.state.elements.wrapper && t.childNodes.length == 0) {
                            re.pop().node !== t && console.error('Objects should be identical');
                            const n = t.parentNode;
                            n.removeChild(t), e(n);
                          }
                        }, ie = !1, le = 0; le < ae;) {
                        const ue = re.pop();
                        if (ue != O.state.elements.cursor) {
                          const ce = ue.type; const pe = ue.node; const
                            de = ue.character;
                          ne && O.options.onRemoveNode && typeof O.options.onRemoveNode == 'function' && O.options.onRemoveNode(pe, de);
                          const ve = pe.parentNode;
                          if (ve) {
                            try {
                              ve.removeChild(pe);
                            } catch (e) {
                              console.error(e);
                            }
                            ce === w && (le++, se(ve));
                          }
                        } else ie = !0;
                      }
                      O.state.visibleNodes = re, ie && O.state.visibleNodes.push(O.state.elements.cursor);
                    }
                    break;
                  case g:
                    var fe = C(O.state.visibleNodes); var he = S.amount; var
                      me = S.callOnRemove;
                    if (fe.length > 0) {
                      for (var ye = O.state.visibleNodes.indexOf(O.state.elements.cursor), ge = fe.splice(ye, fe.length - ye), be = fe.filter((function (e) {
                          return e.type === w;
                        })), Ne = he && he > 0 && he < be.length ? he : be.length, Ee = function e(t) {
                          if (t != O.state.elements.wrapper && t.childNodes.length == 0) {
                            fe.pop().node !== t && console.error('Objects should be identical');
                            const n = t.parentNode;
                            n.removeChild(t), e(n);
                          }
                        }, we = 0; we < Ne;) {
                        if (fe.length > 0) {
                          const Te = fe.pop(); const Ae = Te.type; const Ce = Te.node; const
                            Oe = Te.character;
                          me && O.options.onRemoveNode && typeof O.options.onRemoveNode == 'function' && O.options.onRemoveNode(Ce, Oe);
                          const Se = Ce.parentNode;
                          if (Se) {
                            try {
                              Se.removeChild(Ce);
                            } catch (e) {
                              console.error(e);
                            }
                            Ae === w && (we++, Ee(Se));
                          }
                        }
                      }
                      O.state.visibleNodes = [].concat(C(fe), C(ge));
                    }
                    break;
                  case N:
                    var _e = S.position;
                    if (typeof _e == 'number') {
                      for (var xe = 0, Re = 0; Re < O.state.visibleNodes.length; Re++) {
                        if (xe == _e) {
                          xe = Re;
                          break;
                        }
                        O.state.visibleNodes[Re].type == w && xe++;
                      }
                      O.state.visibleNodes[xe].node.before(O.state.elements.cursor);
                      const Le = O.state.visibleNodes.indexOf(O.state.elements.cursor);
                      O.state.visibleNodes.splice(Le, 1), O.state.visibleNodes.splice(xe, 0, O.state.elements.cursor);
                    }
                    break;
                  case f:
                    O.options.deleteSpeed = o.eventArgs.speed;
                    break;
                  case h:
                    O.options.delay = o.eventArgs.delay;
                    break;
                  case m:
                    O.options.cursor = o.eventArgs.cursor, O.state.elements.cursor.innerHTML = o.eventArgs.cursor;
                }
                O.options.loop && (o.eventName === c || o.eventArgs && o.eventArgs.temp || (O.state.calledEvents = [].concat(C(O.state.calledEvents), [o]))), O.state.eventQueue = r, O.state.lastFrameTime = e;
              }
            }
          })), r) {
            if (typeof r == 'string') {
              const S = document.querySelector(r);
              if (!S) throw new Error('Could not find container element');
              this.state.elements.container = S;
            } else this.state.elements.container = r;
          }
          T && (this.options = A(A({}, this.options), T)), this.state.initialOptions = A({}, this.options), this.init();
        }

        let r; let
          T;
        return r = n, (T = [{
          key: 'init',
          value() {
            let e; let
              t;
            this.setupWrapperElement(), this.addEventToQueue(m, { cursor: this.options.cursor }, !0), !window || window.___TYPEWRITER_JS_STYLES_ADDED___ || this.options.skipAddStyles || (e = '.Typewriter__cursor{-webkit-animation:Typewriter-cursor 1s infinite;animation:Typewriter-cursor 1s infinite;margin-left:1px;width: 0px;margin: 0px;display: inline-block;}@-webkit-keyframes Typewriter-cursor{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes Typewriter-cursor{0%{opacity:0}50%{opacity:1}100%{opacity:0}}', (t = document.createElement('style')).appendChild(document.createTextNode(e)), document.head.appendChild(t), window.___TYPEWRITER_JS_STYLES_ADDED___ = !0), !0 === this.options.autoStart && this.options.strings && this.typeOutAllStrings().start();
          },
        }, {
          key: 'logInDevMode',
          value(e) {
            this.options.devMode && console.log(e);
          },
        }]) && S(r.prototype, T), n;
      }());
      const R = x;
    })(), r.default;
  })();
})));
