import * as React from 'react';

export function useSyncedDataRef<T>(data: T) {
  const dataRef = React.useRef<T>(data);
  dataRef.current = data;
  return dataRef;
}

type TSyncedEnhancedDataRef<T> = T & { ref: React.MutableRefObject<T> };

export function useSyncedEnhancedDataRef<T>(data: T): TSyncedEnhancedDataRef<T> {
  const dataRef = useSyncedDataRef(data);
  return {
    ...data,
    ref: dataRef,
  };
}
