import * as _ from 'lodash';
import { Config } from '../../config/Config';
import { Actions, Parameters } from './DialogFlowActions';
import { RecursivePartial } from '../../../../../../mono/packages/shared-core/src/Types';
import { AppTRPC } from '../../../../shared-core/src/AppTRPC';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';
import { Encoders } from '../../../../../../mono/packages/shared-core/src/Encoders';
import { TMessages } from '../message/Message';

export type TDialogFlowQueryResponse = {
  responseId: string;
  alternativeQueryResults: TDialogFlowQueryResponseResult[];
  queryResult: TDialogFlowQueryResponseResult;
  webhookStatus: null;
}

export type TDialogFlowQueryResponseResult = RecursivePartial<{
  fulfillmentMessages: [
    {
      platform: 'PLATFORM_UNSPECIFIED',
      text: {
        text: Array<string>
      },
      message: 'text'
    }
  ],
  outputContexts: [
    {
      name: string,
      lifespanCount: 1,
      parameters: {
        fields: {
          'no-match': {
            numberValue: 0,
            kind: 'numberValue'
          },
          'no-input': {
            numberValue: 0,
            kind: 'numberValue'
          }
        }
      }
    }
  ],
  knowledgeAnswers: {
    'answers': [
      {
        'source': 'projects/krishanmadan-main/knowledgeBases/OTc1OTI5MDQ5NjkwNzIxNDg0OA/documents/MTUwMDAyNjg4MDEzNTI2NjMwNA',
        'faqQuestion': '',
        'answer': 'William Shakespeare (bapt. 26 April 1564 – 23 April 1616) https://t.co/J3R0YfHCnH was an English playwright, poet and actor. He is widely regarded as the greatest writer in the English language and the world\'s greatest dramatist. @krishanmarco',
        'matchConfidenceLevel': 'HIGH',
        'matchConfidence': 0.9899032115936279
      }
    ]
  },
  queryText: string,
  speechRecognitionConfidence: 0,
  action: string,
  parameters: {
    fields: any;
  },
  allRequiredParamsPresent: true,
  fulfillmentText?: string,
  webhookSource: '',
  webhookPayload: null,
  intent: {
    inputContextNames: [],
    events: [],
    trainingPhrases: [],
    outputContexts: [],
    parameters: [],
    messages: [],
    defaultResponsePlatforms: [],
    followupIntentInfo: [],
    name: string,
    displayName: string,
    priority: 0,
    isFallback: false,
    webhookState: 'WEBHOOK_STATE_UNSPECIFIED',
    action: string,
    resetContexts: false,
    rootFollowupIntentName: '',
    parentFollowupIntentName: '',
    mlDisabled: false,
    liveAgentHandoff: false,
    endInteraction: false
  },
  intentDetectionConfidence: 1,
  diagnosticInfo: null,
  languageCode: string,
  sentimentAnalysisResult: {
    queryTextSentiment: {
      score: 0.699999988079071,
      magnitude: 0.699999988079071
    }
  },
  cancelsSlotFilling: false
}>

export type TDialogFlowQueryParams = {
  sessionId: string;
  text: string;
  languageCode: string;
  // @ts-ignore
  contexts?: TDialogFlowQueryResponseResult['intent']['outputContexts']
}

export class DialogFlow {

  static getContextNames(result: TDialogFlowQueryResponseResult): string[] {
    return (result.outputContexts ?? []).map((context) => {
      return context?.name ?? '';
    });
  }

  static getText(result: TDialogFlowQueryResponseResult): string | null {
    const fulfillmentText = DialogFlow.getFulfillmentText(result);
    const knowledgeAnswer = DialogFlow.getFirstKnowledgeAnswer(result);
    if (!_.isEmpty(fulfillmentText)) {
      return fulfillmentText as string;
    }

    if (!_.isEmpty(knowledgeAnswer)) {
      return knowledgeAnswer as string;
    }

    return DialogFlow.getFirstFulfillmentMessage(result);
  }

  private static getFulfillmentText(result: TDialogFlowQueryResponseResult): string | null {
    return result.fulfillmentText ?? null;
  }

  private static getFirstKnowledgeAnswer(result: TDialogFlowQueryResponseResult): string | null {
    return result.knowledgeAnswers?.answers?.[0]?.answer ?? null;
  }

  private static getFirstFulfillmentMessage(result: TDialogFlowQueryResponseResult): string | null {
    return result.fulfillmentMessages?.[0]?.text?.text?.[0] as string;
  }

  static getParameterSysPersonName(result: TDialogFlowQueryResponseResult): string | null {
    const person = result.parameters?.fields?.[Parameters.person]?.structValue?.fields?.name?.stringValue;
    return person ?? null;
  }

  static getAction(result: TDialogFlowQueryResponseResult): string | undefined {
    return result.action;
  }

  static isIntent(result: TDialogFlowQueryResponseResult, intent: string | string[]): boolean {
    return _.isArray(intent)
      ? intent.includes(result?.intent?.displayName ?? '')
      : intent == result.intent?.displayName;
  }

  static async query(params: TDialogFlowQueryParams): Promise<TDialogFlowQueryResponse> {
    return DialogFlow.client.query('query', {
      sessionId: params.sessionId,
      text: params.text,
      languageCode: params.languageCode,
      contexts: params.contexts,
    });
  };

  static client = AppTRPC.createClient({
    url: `${Config.backendBaseUrl}/trpc`,
  });
}
