import { css } from 'styled-components';
import { TWithTheme } from '../theme/ThemeContext';
import $ from 'jquery';

export const noScrollbars = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
`;

export const globalTransitionTime = '1000ms';

export const cssFillHeightByPercentage = css`
  height: 100%;
  min-height: -webkit-fill-available;
  overflow-y: hidden;
`;

// const height = $(window).height() as number;
// export const vh = (vh: number) => height / 100 * vh;
// export const cssFillHeightIgnoreKeyboard = css`
//   height: ${height}px;
//   overflow-y: hidden;
// `;

export const cssGlobal = css`
  overflow-x: hidden;
  width: 100vw;

  * {
    ${noScrollbars};
  }

  .Typewriter__cursor {
    color: ${({ theme }: TWithTheme) => theme.colors.accent1};
  }
`;

export const cssFontFamily = css`
  font-family: Poppins, sans-serif;
`;

export const makeMedia = (() => {
  type TMakeMediaParams = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  return function (params: TMakeMediaParams) {
    return `
      ${params.sm};
        
      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
        ${params.xs};
      }
  
      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        ${params.sm};
      }
  
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        ${params.md};
      }
  
      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        ${params.lg};
      }
  
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
        ${params.xl};
      }
    `;
  };
})();

export const selectMedia = (() => {
  type TMakeMediaParams = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  return function (params: TMakeMediaParams) {
    const w = $(window).width() as number;
    if (w >= 1200) {
      return params.xl;
    }

    if (w >= 992) {
      return params.lg;
    }

    if (w >= 768) {
      return params.md;
    }

    if (w >= 600) {
      return params.sm;
    }

    if (w < 600) {
      return params.xs;
    }

    return params.sm;
  };
})();

function makeSizes(fontSize: number, letterSpacing: number) {
  return css`
    ${makeMedia({
    xs: `
        ${cssFontFamily};
        font-size: ${pxToVw(fontSize / 0.75)}vw;
        letter-spacing: ${pxToVw(letterSpacing / 0.75)}vw;
      `,
    sm: `
        ${cssFontFamily};
        font-size: ${pxToVw(fontSize)}vw;
        letter-spacing: ${pxToVw(letterSpacing)}vw;
      `,
    md: `
        ${cssFontFamily};
        font-size: ${pxToVw(fontSize / 1.5)}vw;
        letter-spacing: ${pxToVw(letterSpacing / 1.5)}vw;
      `,
    lg: `
        ${cssFontFamily};
        font-size: ${pxToVw(fontSize / 2)}vw;
        letter-spacing: ${pxToVw(letterSpacing / 2)}vw;
      `,
    xl: `
        ${cssFontFamily};
        font-size: ${pxToVw(fontSize / 2.5)}vw;
        letter-spacing: ${pxToVw(letterSpacing / 2.5)}vw;
      `,
  })}
  `;
}

const vwBase = 600;

export function pxToVw(value: number) {
  return value / vwBase * 100;
}

export const cssH1 = makeSizes(93, -1.5);
export const cssH2 = makeSizes(58, -0.5);
export const cssH3 = makeSizes(46, 0);
export const cssH4 = makeSizes(33, 0.25);
export const cssH5 = makeSizes(23, 0);
export const cssH6 = makeSizes(19, 0.15);
export const cssBody1 = makeSizes(15, 0.5);
export const cssBody2 = makeSizes(13, 0.25);
export const cssBody3 = makeSizes(13, 0.10);
