import * as React from 'react';
import { HTMLProps } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { TWithTheme } from '../../theme/ThemeContext';
import { Element } from 'hast';
import { MessageTypeMarkdownFile } from './MessageTypeMarkdownFile';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

type TMessageTypeMarkdownLinkProps = HTMLProps<HTMLAnchorElement> & {
  node: Element;
};

export const MessageTypeMarkdownLink = (props: TMessageTypeMarkdownLinkProps) => {
  const {
    className,
    node,
    ...otherProps
  } = props;

  const isDownload = Utils.safe(
    // @ts-ignore
    () => _.isString(props.children[0]) && `${props.children[0]}`.trim().startsWith('file:'),
    () => false,
  );

  return (
    <Root
      className={className}>
      {isDownload ? (
        <MessageTypeMarkdownFile
          {...props}
        />
      ) : (
        // @ts-ignore
        <Link
          {...otherProps}
          className=""
          target="_blank"
        />
      )}
    </Root>
  );
};

const Root = styled.span`
`;

export const Link = styled.a`
  color: ${({ theme }: TWithTheme) => theme.colors.accent2};
  font-weight: 500;
`;
