import * as React from 'react';
import { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { cssFontFamily } from '../css';
import { ChatContext } from './ChatContext';
import { IconButton, TextField } from '@mui/material';
import { visitorHasSentMessages } from '../../model/message/Message';
import { MessageContext } from '../../model/message/MessageContext';
import { LottieCircle } from '../Lottie';
import { HTMLConst } from '../../lib/HTMLConst';
import { ThemeContext } from '../../theme/ThemeContext';
import { LocaleContext } from '../../model/locale/LocaleContext';
import { ChatViewMessageBoxAnimationContext } from './ChatViewMessageBoxAnimationContext';
import { QuickResponseChips } from '../QuickResponseChips';
import { useTarget } from '../../../../../../mono/packages/lib-react/src/contexts-browser/useTarget';
import * as ReactDOM from 'react-dom';
import { reactSpringPortal } from '../../lib/HTMLComp';
import { useTextInputFocusHandler } from './useTextInputFocusHandler';
import { GlobalContext } from '../GlobalContext';
import SendIcon from '@mui/icons-material/Send';

function useToolbarCircleInTarget() {
  const { introMessageSent } = React.useContext(ChatContext);
  const { messages } = React.useContext(MessageContext);

  const {
    Target: LottieCircleInTarget,
    measureRef: lottieCircleInTargetMeasureRef,
    destroyRef: lottieCircleInTargetDestroyRef,
  } = useTarget(LottieCircle, HTMLConst.sendButtonTarget, React.useCallback((rect: DOMRect) => ({
    top: rect.y - 10,
    left: rect.x - 30,
    width: rect.width + 60,
    transform: 'rotate(-11deg)',
  }), []));

  React.useEffect(() => {
    if (introMessageSent) {
      lottieCircleInTargetMeasureRef.current();
    }
  }, [introMessageSent]);

  const hasSent = visitorHasSentMessages(messages);
  React.useEffect(() => {
    if (hasSent) {
      lottieCircleInTargetDestroyRef.current();
    }
  }, [hasSent]);

  return LottieCircleInTarget;
}

type TChatViewInputProps = {
  className?: string;
};

export const ChatViewInput = ({ className }: TChatViewInputProps) => {
  const {
    chatInput,
    ref: globalContextRef,
  } = React.useContext(GlobalContext);
  const { chatContextRef, onSend } = React.useContext(ChatContext);
  const { theme } = React.useContext(ThemeContext);
  const { strings } = React.useContext(LocaleContext);
  const { chatViewAnimationOriginId } = React.useContext(ChatViewMessageBoxAnimationContext);

  React.useEffect(() => {
    if (chatInput.autoSend) {
      chatContextRef.current.onSend(globalContextRef.current.chatInput.input);
      chatInput.setAutoSend(false);
      setTimeout(() => {
        chatInput.setInput('');
      }, 0)
    }
  }, [chatContextRef, chatInput.autoSend]);

  const textInputFocusHandler = useTextInputFocusHandler();
  const onSendMessage = React.useCallback((event: any) => {
    textInputFocusHandler.refocusInput();
    event?.stopPropagation();
    event?.preventDefault();
    if (`${globalContextRef.current.chatInput.input}`.length <= 0) {
      return;
    }

    onSend(globalContextRef.current.chatInput.input);
    globalContextRef.current.chatInput.setInput('');
  }, [globalContextRef, onSend]);

  const onEnterPress = React.useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    event.key === 'Enter' && onSendMessage(event);
  }, [onSendMessage]);

  const setInputValue = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    return globalContextRef.current.chatInput.setInput(event.target.value);
  }, [globalContextRef]);

  const LottieCircleInTarget = useToolbarCircleInTarget();

  return (
    <Root className={className}>
      <StyledQuickResponseChips
        setInput={chatInput.setInput}
        textInputFocusHandler={textInputFocusHandler}
      />
      <InputContainer>
        <StyledTextField
          inputProps={{ form: { autocomplete: 'off' } }}
          autoFocus
          fullWidth
          key={chatViewAnimationOriginId}
          id={chatViewAnimationOriginId}
          inputRef={textInputFocusHandler.inputRef}
          inputcolor={theme.colors.positive}
          label={strings.messageTextInputLabel}
          variant="standard"
          type="text"
          value={chatInput.input}
          onChange={setInputValue}
          onKeyPress={onEnterPress}
          color={chatInput.input.length <= 0 ? undefined : 'success'}
          placeholder={strings.messageTextInputLabel}
        />
        <>
          {ReactDOM.createPortal(
            (
              <LottieCircleInTarget
                color={[theme.colors.accent1]}
              />
            ), reactSpringPortal,
          )}
          <IconButton
            id={HTMLConst.sendButtonTarget}
            sx={{ p: '10px' }}
            aria-label="send"
            color="success"
            {...textInputFocusHandler.refocusHoc(onSendMessage)}
          >
            <SendIcon/>
          </IconButton>
        </>
      </InputContainer>
    </Root>
  );
};

const Root = styled.div`
  ${cssFontFamily};
  width: 100%;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 16px;
`;

const StyledTextField = styled(TextField)<{ inputcolor: string }>`
  .MuiInputBase-root {
    :before {
      border-bottom-color: ${({ inputcolor }) => inputcolor};
    }
  }

  .MuiInput-root {
    color: ${({ inputcolor }) => inputcolor};
  }

  .MuiInputLabel-root {
    color: ${({ inputcolor }) => inputcolor};
  }

`;

const StyledQuickResponseChips = styled(QuickResponseChips)`
  margin-bottom: 24px;
`;
