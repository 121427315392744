import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { EMessageType, TMessage } from '../message/Message';
import { VisitorContext } from '../user/VisitorContext';
import { useDialogFlowQuickResponses } from './useDialogFlowQuickResponses';
import { useDialogFlowName } from './useDialogFlowName';
import { DialogFlow, TDialogFlowQueryResponseResult } from './DialogFlow';
import { useDialogFlowLinkInterceptor } from './useDialogFlowLinkInterceptor';
import { localeToLanguage } from '../locale/ELocale';
import { LocaleContext } from '../locale/LocaleContext';
import { Log } from '../../instance/Log';
import { useDialogFlowActions } from './useDialogFlowActions';

type TOnSendTextParams = {
  text: string;
};

const dialogFlowContextValue = {
  onSendText: (() => undefined) as any as ((params: TOnSendTextParams) => Promise<{
    result: Partial<TMessage>;
    onPostProcess: () => Promise<any>;
  }>),
  dialogFlowActions: {} as ReturnType<typeof useDialogFlowActions>,
  dialogFlowQuickResponses: {} as ReturnType<typeof useDialogFlowQuickResponses>,
  processDialogFlowIntentResultRef: { current: _.noop as (result: TDialogFlowQueryResponseResult) => void },
};

export const DialogFlowContext = React.createContext({
  ...dialogFlowContextValue,
  dialogFlowContextRef: null as any as React.MutableRefObject<typeof dialogFlowContextValue>,
});

type TVisitorContextProps = {
  //
};

export const DialogFlowContextProvider = ({ children }: PropsWithChildren<TVisitorContextProps>) => {
  const { visitorContextRef } = React.useContext(VisitorContext);
  const { localeContextRef } = React.useContext(LocaleContext);
  const dialogFlowName = useDialogFlowName();
  const dialogFlowActions = useDialogFlowActions();
  const dialogFlowQuickResponses = useDialogFlowQuickResponses();

  const processDialogFlowIntentResultRef = useSyncedDataRef((result: TDialogFlowQueryResponseResult) => {
    [
      dialogFlowName,
      dialogFlowActions,
      dialogFlowQuickResponses,
    ].forEach((value) => {
      value.handle(result);
    });
  });

  const previousResultRef = React.useRef<TDialogFlowQueryResponseResult | null>(null);

  const onSendText = React.useCallback(async ({ text }: TOnSendTextParams) => {
    const response = await DialogFlow.query({
      sessionId: `session-${visitorContextRef.current.visitor.id}`.toLowerCase(),
      text,
      languageCode: localeToLanguage(visitorContextRef.current.visitor.locale),

      // Pipe previous context in for a conversational flow
      ...((previousResultRef.current?.outputContexts?.length ?? 0) > 0 ? { contexts: previousResultRef.current?.outputContexts } : {}),
    });

    const result = response.queryResult;
    previousResultRef.current = result;

    Log.v('DialogFlowContext', 'onSendText', `Result`, result);

    const responseText = DialogFlow.getText(result);
    return {
      result: {
        value: {
          type: responseText === 'null' ? EMessageType.NoDisplay : EMessageType.Text,
          text: !_.isEmpty(responseText)
            ? responseText
            : localeContextRef.current.strings.messageTextOwnerDefaultMessage,
        },
      },

      onPostProcess: async () => {
        processDialogFlowIntentResultRef.current(result);
      },
    };
  }, [visitorContextRef, previousResultRef]);

  const dialogFlowContextValue = {
    onSendText,
    dialogFlowActions,
    dialogFlowQuickResponses,
    processDialogFlowIntentResultRef,
  };

  const dialogFlowContextRef = useSyncedDataRef(dialogFlowContextValue);

  useDialogFlowLinkInterceptor(processDialogFlowIntentResultRef);
  return (
    <DialogFlowContext.Provider
      value={{
        dialogFlowContextRef,
        ...dialogFlowContextValue,
      }}
    >
      {children}
    </DialogFlowContext.Provider>
  );
};

