import * as React from 'react';
import styled from 'styled-components';
import { MessageContext } from '../../model/message/MessageContext';
import { ChatViewMessageRow } from './ChatViewMessageRow';

type TChatViewProps = {
  //
};

export const MessageList = ({}: TChatViewProps) => {
  const { displayMessages } = React.useContext(MessageContext);

  return (
    <>
      {displayMessages.map((message) => (
        <ChatViewMessageRow
          key={message.id}
          message={message}
        />
      ))}
    </>
  );
};

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
