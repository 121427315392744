export const Parameters = {
  person: 'person'
} as const;

export const Actions = {
  sound: 'sound', // on | off | toggle
  openCalendar: 'openCalendar', // tech | training
  theme: 'theme',
  inputKey: 'inputKey',
  inputKeySend: 'inputKeySend',
  submitConversation: 'submitConversation',
  language: 'language', // en | it
} as const;

export const Intents = {
  nameUserChange: 'name.user.change',
  nameUserConfirm: 'name.user.confirm',
  nameUserDelete: 'name.user.delete',
  nameUserFeedbackBad: 'name.feedback.bad',
  nameUserFeedbackGood: 'name.feedback.good',
  nameUserGet: 'name.user.get',
  nameUserSave: 'name.user.save',

  meAboutWorkTimeular: 'me.about.work.timeular',
  meAboutWorkMindTek: 'me.about.work.mindtek',
  meAboutWorkRuntastic: 'me.about.work.runtastic',
  meAboutTraining: 'me.about.training',
  meAboutProjectsAlacarte: 'me.about.projects.alacarte',
} as const;
