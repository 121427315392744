import * as React from 'react';
import styled from 'styled-components';
import { DialogFlowContext } from '../model/dialogFlow/DialogFlowContext';
import { VisitorContext } from '../model/user/VisitorContext';
import { LocaleContext } from '../model/locale/LocaleContext';
import { ModalContentView } from './ModalContent';
import { InlineWidget } from 'react-calendly';
import { selectMedia } from './css';

type TCalendarModalProps = {
  //
};

export const CalendarModal = (props: TCalendarModalProps) => {
  const { strings } = React.useContext(LocaleContext);
  const { dialogFlowActions } = React.useContext(DialogFlowContext);
  const { visitor } = React.useContext(VisitorContext);

  const closeCalendar = React.useCallback(() => {
    dialogFlowActions.setCalendarUrl(null);
  }, [dialogFlowActions]);

  return (
    <ModalContentView
      open={dialogFlowActions.calendarUrl != null}
      onClose={closeCalendar}
      content={(
        <Root>
          <InlineWidget
            styles={{
              height: '80vh',
              width: selectMedia({
                xs: '90vw',
                sm: '90vw',
                md: '60vw',
                lg: '40vw',
                xl: '40vw',
              }),
            }}
            url={dialogFlowActions.calendarUrl ?? strings.calendarTech}
            pageSettings={{
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
            }}
            prefill={{ name: visitor.name }}
          />
        </Root>
      )}
    />
  );
};

const Root = styled.div`
`;
