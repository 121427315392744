import * as React from 'react';
import { mobileAndTabletCheck } from '../../lib/mobileCheck';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';

export function useTextInputFocusHandler() {
  const inputRef = React.createRef<HTMLInputElement>();
  const inputIsFocusedRef = React.useRef<boolean>();
  React.useEffect(() => {
    function checkInputIsFocused() {
      inputIsFocusedRef.current = document.activeElement === inputRef.current;
    }

    checkInputIsFocused();
    const interval = setInterval(() => checkInputIsFocused(), 500);
    return () => clearInterval(interval);
  }, [inputRef]);

  const refocusInput = React.useCallback(() => {
    if (inputIsFocusedRef.current) {
      inputRef.current?.focus();
    }
  }, [inputRef, inputIsFocusedRef]);

  const refocusHoc = React.useCallback(<T extends (...any: any) => any>(callback: T) => {
    const enhancedCallback = function (event: MouseEvent, ...others: any) {
      if (inputIsFocusedRef.current) {
        inputRef.current?.focus();
        event?.stopPropagation();
        event?.preventDefault();
      }
      return callback(event, ...others);
    };

    return { ...(mobileAndTabletCheck() ? { onMouseDown: enhancedCallback as T } : { onClick: enhancedCallback as T }) };
  }, [inputRef]);

  const value = {
    inputRef,
    refocusInput,
    refocusHoc,
  };

  const textInputFocusHandlerRef = useSyncedDataRef(value);
  return {
    textInputFocusHandlerRef,
    ...value,
  };
}

