import { ELocale } from '../locale/ELocale';

export enum ESender {
  System = 'System',
  Visitor = 'Visitor',
}

export type TUser = {
  id: string;
  name: string;
  type: ESender;
  locale: ELocale;
};
