import { getUserLocale } from 'get-user-locale';

export enum ELocale {
  'en-US' = 'en-US',
  'it-IT' = 'it-IT',
}

export enum ELang {
  'en' = 'en',
  'it' = 'it',
}

export const localeDefault = (function localeDefault(): ELocale {
  const result = getUserLocale();

  if (result.startsWith('it')) {
    return ELocale['it-IT'];
  }

  return ELocale['en-US'];
}());

export function localeToLanguage(locale: ELocale) {
  switch (locale) {
    case ELocale['en-US']:
      return ELang.en;
    case ELocale['it-IT']:
      return ELang.it;
  }
}

export function languageToLocale(language: string) {
  if (`${language}`.toLowerCase() === 'it') {
    return ELocale['it-IT'];
  }
  if (`${language}`.toLowerCase() === 'italian') {
    return ELocale['it-IT'];
  }
  if (`${language}`.toLowerCase() === 'italiano') {
    return ELocale['it-IT'];
  }
  if (`${language}`.toLowerCase() === 'italy') {
    return ELocale['it-IT'];
  }
  if (`${language}`.toLowerCase() === 'en') {
    return ELocale['en-US'];
  }
  if (`${language}`.toLowerCase() === 'english') {
    return ELocale['en-US'];
  }
  if (`${language}`.toLowerCase() === 'inglese') {
    return ELocale['en-US'];
  }
  if (`${language}`.toLowerCase() === 'eng') {
    return ELocale['en-US'];
  }
  return null;
}
