import Typewriter from './typewriter-effect/core';

function pauseSpeed(ms: number): number {
  return ms * 0.8;
}

function typeSpeed(ms: number): number {
  return ms * 0.8;
}

export function typeName(htmlId: string, onComplete?: () => void) {
  const type = new Typewriter(`#${htmlId}`, { delay: typeSpeed(75) });
  type.pauseFor(pauseSpeed(200))
    .typeString('Krishan')
    .typeString(' Madan')
    .pauseFor(pauseSpeed(400))
    .deleteChars(1)
    .pauseFor(pauseSpeed(150))
    .deleteChars(1)
    .pauseFor(pauseSpeed(100))
    .deleteChars(1)
    .pauseFor(pauseSpeed(600))
    .typeString('r')
    .pauseFor(pauseSpeed(300))
    .typeString('c')
    .pauseFor(pauseSpeed(300))
    .typeString('o')
    .pauseFor(pauseSpeed(300))
    .typeString(' ')
    .typeString('Madan')
    .pauseFor(pauseSpeed(200))
    .callFunction((state: any) => {
      state.elements.cursor.style.display = 'none';
      type.stop();
      onComplete?.();
    })
    .start();
}

export function typePosition(
  htmlId: string,
  htmlUnderlineSpanId: string,
  onComplete?: () => void,
) {
  const type = new Typewriter(`#${htmlId}`, { delay: typeSpeed(75) });
  type.pauseFor(pauseSpeed(1000))
    .typeString('Mid')
    .pauseFor(pauseSpeed(700))
    .deleteChars(3)
    .typeString(` <span id="${htmlUnderlineSpanId}">Senior</span>`)
    .pauseFor(pauseSpeed(400))
    .typeString(' Software')
    .typeString(' Developer')
    .pauseFor(pauseSpeed(500))
    .callFunction((state: any) => {
      state.elements.cursor.style.display = 'none';
      type.stop();
      onComplete?.();
    })
    .start();
}
