import * as React from 'react';
import styled from 'styled-components';
import { ChatViewMessageBoxAnimationContext } from './ChatViewMessageBoxAnimationContext';
import { KeyboardContext } from './KeyboardContext';
import { useMeasure } from '../../../../../../mono/packages/lib-react/src/contexts-browser/useMeasure';
import { MessageList } from './MessageList';
import { ChatViewInput } from './ChatViewInput';

type TChatViewProps = {
  //
};

export const Chat = ({}: TChatViewProps) => {
  const {
    measurementRef,
    measurements,
  } = useMeasure();
  const { chatListScroller } = React.useContext(ChatViewMessageBoxAnimationContext);
  const { keyboardOpen } = React.useContext(KeyboardContext);
  return (
    <Root>
      {!keyboardOpen && <Flexer ref={measurementRef}/>}
      <Scroll
        height={measurements?.height ?? 0}
        ref={chatListScroller?.scrollableRefInternal}
        keyboardOpen={keyboardOpen}>
        <SubRoot>
          <MessageList/>
        </SubRoot>
      </Scroll>
      <StyledChatViewInputContainer>
        <ChatViewInput/>
      </StyledChatViewInputContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Flexer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

const Scroll = styled.div<{ keyboardOpen: boolean; height: number }>`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  ${({ keyboardOpen, height }) => (keyboardOpen ? `
  ` : `
    position: absolute;
    overflow: auto;
    flex-direction: column-reverse;
    min-height: ${height - 25}px;
    max-height: ${height - 25}px;
  `)};
`;

const SubRoot = styled.div`
  width: 100%;
`;

const StyledChatViewInputContainer = styled.div`
`;
