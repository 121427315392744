import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as fp from 'lodash/fp';
import * as _ from 'lodash';
import { EMessageType, messageSortByTimestamp, TMessage, TMessages, TMessageWithoutId } from './Message';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { useSystemUser } from '../user/useUsers';

const messagesContextValue = {
  messages: {} as TMessages,
  displayMessages: [] as TMessage[],
  setMessages: (() => ({})) as any as (set: ((messages: TMessages) => TMessages)) => void,
  setMessage: (() => ({})) as any as (id: string, set: (message: TMessageWithoutId) => TMessageWithoutId) => void,
};

export const MessageContext = React.createContext({
  ...messagesContextValue,
  messageContextRef: null as any as React.MutableRefObject<typeof messagesContextValue>,
});

type TMessageContextProps = {
  //
};
export const MessageContextProvider = ({ children }: PropsWithChildren<TMessageContextProps>) => {
  const [messages, setMessages] = React.useState<TMessages>(messagesContextValue.messages);

  const userSystem = useSystemUser();

  const setMessage = React.useCallback((id: string, set: (message: TMessageWithoutId) => TMessageWithoutId) => {
    setMessages((messages) => {
      // Find previous
      const upsert: TMessage = {
        // Use provided id
        id,

        // Provide default values in case the message is completely new
        isoTimestamp: new Date().toISOString(),
        user: userSystem,
        value: { type: EMessageType.NoDisplay },

        // Override defaults with previous value in case the message already exists
        ..._.find(messages, (message) => message.id === id),
      };

      const upserted: TMessage = {
        // Use provided mapper
        ...set(_.cloneDeep(upsert)),

        // Ensure the provided id is used
        id,
      };

      return fp.set(upsert.id, upserted, messages);
    });
  }, [userSystem, setMessages]);

  const messageContextValue = {
    messages,
    setMessages,
    setMessage,
    displayMessages: _.values(messages)
      .filter((message) => message.value.type !== EMessageType.NoDisplay)
      .sort(messageSortByTimestamp),
  };

  const messageContextRef = useSyncedDataRef(messageContextValue);
  return (
    <MessageContext.Provider
      value={{
        messageContextRef,
        ...messageContextValue,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
