import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate } from 'react-dom';
import { App } from './App';

const rootElement = document.getElementById('main') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
if (rootElement.hasChildNodes()) {
  hydrate(<App/>, rootElement);
} else {
  root.render(<App/>);
}
