import * as React from 'react';
import { LocaleContext } from '../locale/LocaleContext';
import * as _ from 'lodash';
import { DialogFlow, TDialogFlowQueryResponseResult } from './DialogFlow';
import { Config } from '../../config/Config';

export type TQuickResponse = {
  label: string;
  icon?: string;
  contextMatchStrength: number;
};

/**
 * If strings has quickResponses.{context}[0].value defined, then that becomes a quick response
 */
export const useDialogFlowQuickResponses = () => {
  const { strings } = React.useContext(LocaleContext);

  const [context, setContext] = React.useState<string[]>([]);

  const quickResponses = React.useMemo(() => {
    const newQuickResponses: TQuickResponse[] = context.flatMap((context, index) => {
      return contextToQuickResponse(strings, context, index);
    });

    return newQuickResponses.length > 0
      ? newQuickResponses
      : contextToQuickResponse(strings, Config.defaultQuickResponseContext);
  }, [context, strings]);

  const handle = React.useCallback((result: TDialogFlowQueryResponseResult) => {
    setContext(DialogFlow.getContextNames(result));
  }, [setContext]);

  return {
    handle,
    quickResponses,
  };
};

function contextToQuickResponse(
  localizedStrings: any,
  contextNameOrPath: string,
  strengthOffset = 1,
): TQuickResponse[] {
  const parsedContext = _.last(`${contextNameOrPath}`.split('/'));

  const localeKeys: Array<{ value: string; icon?: string }> = localizedStrings?.quickResponses?.[parsedContext ?? ''];
  if (!localeKeys) {
    return [];
  }

  return localeKeys.map((localizedQuickResponse, subIndex) => ({
    label: localizedQuickResponse.value,
    icon: localizedQuickResponse.icon,
    contextMatchStrength: strengthOffset * subIndex,
  }));
}
