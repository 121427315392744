import * as React from 'react';
import styled from 'styled-components';
import { EMessageType, TMessage } from '../../model/message/Message';
import { LottieTypingMessage } from '../Lottie';
import { TWithTheme } from '../../theme/ThemeContext';
import { cssBody1, cssBody2 } from '../css';
import { ChatConst } from './ChatConst';
import { MessageTypeMarkdown } from './MessageTypeMarkdown';

type TChatViewMessageBoxProps = {
  message: TMessage;
  left: boolean;
  className?: string;
};

export const ChatViewMessageBox = ({
  message,
  left,
  className,
}: TChatViewMessageBoxProps) => {
  return (
    <Root className={className}>
      {message.value.type === EMessageType.Typing && (
        <MessageContentTyping left={left}>
          <StyledLottieTypingMessage/>
        </MessageContentTyping>
      )}
      {message.value.type !== EMessageType.Typing && (
        <Content left={left}>
          <ArrowContainer left={left}>
            {left ? (
              <ArrowLeft/>
            ) : (
              <ArrowRight/>
            )}
          </ArrowContainer>
          <MessageName>
            {message.user.name}
          </MessageName>
          <MessageTextValue>
            {message.value.type === EMessageType.Text && (
              <MessageTypeMarkdown
                message={message}
              />
            )}
          </MessageTextValue>
        </Content>
      )}
    </Root>
  );
};

export const Root = styled.div`
  display: flex;
`;

type TContentProps = {
  left: boolean;
};

const Content = styled.div<TContentProps>`
  ${ChatConst.messageMediaParams};
  background: ${({
    theme,
    left,
  }: TWithTheme<TContentProps>) => (left ? theme.colors.chatHeadLeft : theme.colors.chatHeadRight)};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 12px 24px;
  position: relative;
`;

const MessageContentTyping = styled(Content)<TContentProps>`
  background: transparent;
`;

const ArrowContainer = styled.div<TContentProps>`
  position: absolute;
  ${({ left }) => (left ? 'right: -10px' : 'left: -10px')};
`;

const ArrowBase = styled.div`
  margin: 4px 0;
`;

const ArrowLeft = styled(ArrowBase)`
  :after {
    border-bottom: 10px solid transparent;
    border-left: 10px solid ${({ theme }: TWithTheme) => theme.colors.chatHeadLeft};
    border-top: 10px solid transparent;
    content: '';
    height: 0;
    margin: -10px -10px;
    position: absolute;
    width: 0;
  }
`;

const ArrowRight = styled(ArrowBase)`
  :after {
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${({ theme }: TWithTheme) => theme.colors.chatHeadRight};
    border-top: 10px solid transparent;
    content: '';
    height: 0;
    margin: -10px 0;
    position: absolute;
    width: 0;
  }
`;

const MessageText = styled.div`
  ${ChatConst.messageContentMediaParams};
  color: ${({ theme }: TWithTheme) => theme.colors.primaryText};
  float: left;
  overflow-wrap: break-word;
  text-align: left;
  word-wrap: break-word;
`;

const MessageName = styled(MessageText)`
  ${cssBody1};
  font-weight: 600;
`;

const MessageTextValue = styled(MessageText)`
  ${cssBody2};
`;

const height = 80;
const StyledLottieTypingMessage = styled(LottieTypingMessage)`
  height: ${height}px;
  margin-bottom: -24px;
  margin-left: -24px;
  margin-top: -24px;
  max-height: ${height}px;
  min-height: ${height}px;
  overflow: hidden;
  width: 93px;
`;
