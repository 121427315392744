import { Secrets } from '../../../../../mono/packages/shared-core/src/Secrets';
import CoreSharedCoreSecretKeys from '../../../../../mono/packages/shared-core/src/config/SecretKeys';
import SecretKeys from '../config/SecretKeys';
import { PackageConst } from '../config/PackageConst';

const mergedSecrets = {
  ...CoreSharedCoreSecretKeys,
  ...SecretKeys,
};

export const PackageSecrets = new Secrets<typeof mergedSecrets>({
  packageName: PackageConst.appName,
  packageSecretKeys: mergedSecrets
});
