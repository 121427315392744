import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { typeName, typePosition } from '../lib/typers';
import { cssH4, cssH6 } from './css';
import { LottieUnderline } from './Lottie';
import { HTMLConst } from '../lib/HTMLConst';
import { ThemeContext, TWithTheme } from '../theme/ThemeContext';
import { reactSpringPortal } from '../lib/HTMLComp';
import { LocaleContext } from '../model/locale/LocaleContext';
import { useTarget } from '../../../../../mono/packages/lib-react/src/contexts-browser/useTarget';
import { SocialBar } from './SocialBar';

type THeaderProps = {
  className?: string;
};

export const Header = ({ className }: THeaderProps) => {
  const { strings } = React.useContext(LocaleContext);
  const {
    Target: LottieUnderlineInTarget,
    measureRef: lottieUnderlineInTargetMeasureRef,
  } = useTarget(LottieUnderline, HTMLConst.headerUnderlineTarget, React.useCallback((rect: DOMRect) => ({
    top: rect.y + 21,
    left: rect.left - 6,
    width: rect.width + 25,
  }), []));

  React.useEffect(() => {
    typeName(HTMLConst.headerName, () => {
      typePosition(HTMLConst.headerPosition1, HTMLConst.headerUnderlineTarget, () => {
        lottieUnderlineInTargetMeasureRef.current();
      });
    });
  }, []);

  const theme = React.useContext(ThemeContext);
  return (
    <Root className={className}>
      <ShadowContent>
        <ShadowContentTextContainer>
          <TextName>{strings.systemFullName}</TextName>
          <IntroContainer>
            <TextIntro>
              {strings.systemFullIntro}
            </TextIntro>
          </IntroContainer>
        </ShadowContentTextContainer>
        <IconsContainer>
          <SocialBar/>
        </IconsContainer>
      </ShadowContent>
      <TypedContent>
        <TextContainer>
          <TextName id={HTMLConst.headerName}></TextName>
          <IntroContainer>
            <TextIntro id={HTMLConst.headerPosition1}></TextIntro>
          </IntroContainer>
        </TextContainer>
      </TypedContent>
      {ReactDOM.createPortal(
        (
          <LottieUnderlineInTarget
            color={[theme.theme.colors.accent1]}
          />
        ), reactSpringPortal,
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const TypedContent = styled(Content)`
  position: absolute;
  top: 0;
`;

const ShadowContent = styled(Content)`
  position: relative;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const ShadowContentTextContainer = styled(TextContainer)`
  visibility: hidden;
`;

const IconsContainer = styled.div`
  margin-top: 12px;
`;

const IntroContainer = styled.div`

`;

const Text = styled.h1`
  color: ${({ theme }: TWithTheme) => theme.colors.primaryText};
  display: block;
  margin: 0;
  max-width: 87vw;
  text-transform: uppercase;
`;

const TextName = styled(Text)`
  ${cssH4};
`;

const TextIntro = styled(Text)`
  ${cssH6};
  position: relative;
`;
