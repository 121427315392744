import {z} from 'zod';

export const queryParams = z.object({
  sessionId: z.string().optional(),
  text: z.string(),
  languageCode: z.string(),
  contexts: z.any().array().optional(),
})

export type TQueryParams = z.infer<typeof queryParams>;

export const knowledgeParams = z.object({
  knowledgeBase: z.string(),
  documentName: z.string(),
  document: z.string(),
  documentMimeType: z.string(),
  documentKnowledgeTypes: z.string().array().optional(),
})

export type TKnowledgeParams = z.infer<typeof knowledgeParams>;

export const intentParams = z.object({
  brain: z.any()
})

export type TIntentParams = z.infer<typeof intentParams>;

export const knowledgeTweetsParams = z.object({
  knowledgeBase: z.string(),
  documentName: z.string(),
  document: z.string(),
  documentMimeType: z.string(),
  documentKnowledgeTypes: z.string().array().optional(),
})

export type TKnowledgeTweetsParams = z.infer<typeof knowledgeTweetsParams>;

export const conversation = z.object({
  messages: z.any(),
})
