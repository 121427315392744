import { conversation, intentParams, knowledgeParams, knowledgeTweetsParams, queryParams } from './Model';
import { DialogFlow } from '../../app-frontend/src/model/dialogFlow/DialogFlow';
import { processDocumentForKnowledgeBase } from '../../app-functions/src/lib/processDocumentForKnowledgeBase';
import { DialogFlowIntentsAPI } from '../../app-functions/src/lib/dialogFlow/DialogFlowIntentsAPI';
import { TRPC, TTRPCStatusEndpointResult } from '../../../../mono/packages/shared-core/src/tRPC/TRPC';

export const AppTRPC = new TRPC({
  defineServer: function (params) {
    const {
      createRouter,
      createResolver,
    } = params;

    return createRouter()
      .query('status', {
        resolve: createResolver<TTRPCStatusEndpointResult>(),
      })
      .query('query', {
        input: queryParams,
        resolve: createResolver<ReturnType<typeof DialogFlow.query>>(),
      })
      .mutation('knowledge', { // learn
        input: knowledgeParams,
        resolve: createResolver<ReturnType<typeof processDocumentForKnowledgeBase>>(),
      })
      .mutation('knowledge.tweets', { // thoughts
        input: knowledgeTweetsParams,
        resolve: createResolver<ReturnType<typeof processDocumentForKnowledgeBase>>(),
      })
      .mutation('intents', {
        input: intentParams,
        resolve: createResolver<ReturnType<InstanceType<typeof DialogFlowIntentsAPI>['upsertBrain']>>(),
      })
      .mutation('conversation.submit', {
        input: conversation,
        resolve: createResolver<{}>(),
      });
  },
});
