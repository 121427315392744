/* eslint-disable no-console */
import dot from 'dot-object';
import * as _ from 'lodash';

enum ELoggerLevel {
  v = 'v',
  d = 'd',
  i = 'i',
  w = 'w',
  e = 'e',
  wtf = 'wtf',
}

type TLoggerCallbackParams = {
  level: ELoggerLevel;
  line: string;
  structured: any;
}

type TLoggerParams = {
  name: string;
  stdout: boolean;
  history: boolean;
  overrideStdout?: (p: TLoggerCallbackParams) => void;
  onLog?: (p: TLoggerCallbackParams) => void;
};

export class Logger {
  static readonly ELoggerLevel = ELoggerLevel;

  static padStr(str: string, len: number): string {
    return str.substr(0, len).padEnd(len, ' ');
  }

  static prettyObjects(strs: string[]): string[] {
    return strs.map(item => {
      if (item == null || typeof item !== 'object') {
        return item;
      }

      const flat = dot.dot(item);
      return _.keys(flat).reduce((acc, key: string) => {
        return `${acc}\n\t${Logger.padStr(key, 30)}${flat[key]}`;
      }, '');
    });
  }

  readonly params: TLoggerParams;

  readonly history: string[] = [];

  constructor(params: TLoggerParams) {
    this.params = params;
    this.log = this.log.bind(this);
  }

  /**
   * Use this when you want to go absolutely nuts with your logging.
   * If for some reason you've decided to log every little thing in
   * a particular part of your app, use the Log.v tag.
   */
  v(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.v, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  /**
   * Use this for debugging purposes.
   * If you want to print out a bunch of messages so you can log the
   * exact flow of your program, use this. If you want to keep a log
   * of variable values, use this.
   */
  d(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.d, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  /**
   * Use this to post useful information to the log.
   * For example: that you have successfully connected to a server.
   * Basically use it to report successes.
   */
  i(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.i, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  /**
   * Something terribly wrong had happened, that must be investigated immediately.
   * No system can tolerate items logged on this level.
   * Example: NPE, dbUser unavailable, mission critical use case cannot be continued.
   */
  w(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.w, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  /**
   * This is for when bad stuff happens.
   * Use this tag in places like inside a catch statement.
   * You know that an error has occurred and therefore you're logging an error.
   */
  e(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.e, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  /**
   * Use this when stuff goes absolutely, horribly, holy-crap wrong.
   * You know those catch blocks where you're catching errors that you
   * never should get...yeah, if you wanna log them use Log.wtf
   */
  wtf(fileOrClass: string, method: string, ...params: any) {
    this.log(ELoggerLevel.wtf, this.params.name, `${fileOrClass}@${method}`, ...params);
  }

  private log(...[level, scopeId, ...others]: [ELoggerLevel, string, ...any[]]) {
    const structured = [
      new Date().toISOString(),
      Logger.padStr(level, 3),
      Logger.padStr(scopeId, 40),
      ...others,
    ];

    const line = structured.join('');

    if (this.params.history) {
      this.history.push(line);
    }

    this.params.onLog?.({ level, line, structured });

    if (this.params.stdout) {
      if (this.params.overrideStdout) {
        this.params.overrideStdout?.({ level, line, structured });
        return;
      }

      console.log(...structured);
    }
  }
}
