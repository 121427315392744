import * as React from 'react';
import { WindowContext } from './WindowContext';

export function usePersistedState<T>(key: string, initial: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const { window, windowRef } = React.useContext(WindowContext);

  const [value, setValue] = React.useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : initial;
  });

  React.useEffect(() => {
    windowRef.current.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
