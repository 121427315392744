import * as React from 'react';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { TDialogFlowQueryResponseResult } from './DialogFlow';
import { Actions } from './DialogFlowActions';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';
import { Log } from '../../instance/Log';

const linkMappers = [
  ...Object.keys(Actions).map((action) => ({
    capture: (link: string) => {
      const url = Utils.safe(() => new URLSearchParams(link));
      return url?.has(action) ?? false;
    },
    do: (link: string) => (<TDialogFlowQueryResponseResult>{ action: link }),
  })),
];

type TProcessRef = {
  current: (result: TDialogFlowQueryResponseResult) => void;
};

export const useDialogFlowLinkInterceptor = (processRef: TProcessRef) => {
  const onInterceptLinkRef = useSyncedDataRef((event: React.MouseEvent) => {
    // @ts-ignore
    const target = event.target || event.srcElement;
    // @ts-ignore
    if (target.tagName === 'A') {
      // @ts-ignore
      const href = target.getAttribute('href');

      const matches = linkMappers.filter((map) => map.capture(href));
      if (matches.length > 0) {
        Log.v('useDialogFlowLinkInterceptor', 'useDialogFlowLinkInterceptor', `Matched ${href}`);
        event.preventDefault();
      }

      matches.forEach((match) => {
        processRef.current(match.do(href));
      });
    }
  });

  React.useEffect(() => {
    function listener(event: any) {
      onInterceptLinkRef.current(event);
    }

    document?.addEventListener?.('click', listener);

    // @ts-ignore
    document?.attachEvent?.('onclick', listener);

    return () => {
      document?.removeEventListener('click', listener);
    };
  }, [onInterceptLinkRef]);
};
