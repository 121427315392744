import * as React from 'react';
import { PropsWithChildren } from 'react';
import $ from 'jquery';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { useToggle } from '../../../../../../mono/packages/lib-react/src/hooks/useToggle';

const keyboardContextValue = {
  keyboardOpen: false,
  setKeyboardOpen: (() => undefined) as () => void,
  setKeyboardClosed: (() => undefined) as () => void,
};

export const KeyboardContext = React.createContext({
  ...keyboardContextValue,
  keyboardContextRef: { current: keyboardContextValue },
});

type TKeyboardContextProviderProps = {
  //
};

export const KeyboardContextProvider = ({ children }: PropsWithChildren<TKeyboardContextProviderProps>) => {
  const [keyboardOpen, , setKeyboardOpen, setKeyboardClosed] = useToggle(false);
  const initialWidthRef = React.useRef($(window).width());
  const initialHeightRef = React.useRef($(window).height());
  React.useEffect(() => {
    $(window).resize(function () {
      if ($('input').is(':focus')) {
        const width = $(window).width();
        const height = $(window).height();

        // @ts-ignore
        if (initialWidthRef.current == width && initialHeightRef.current > height) {
          setKeyboardOpen();
        } else {
          setKeyboardClosed();
        }
      } else {
        setKeyboardClosed();
      }
    });
  }, [initialWidthRef, initialHeightRef]);

  const keyboardContextValue = {
    keyboardOpen,
    setKeyboardOpen,
    setKeyboardClosed,
  };

  const keyboardContextRef = useSyncedDataRef(keyboardContextValue);

  return (
    <KeyboardContext.Provider
      value={{
        ...keyboardContextValue,
        keyboardContextRef,
      }}
    >
      {children}
    </KeyboardContext.Provider>
  );
};
