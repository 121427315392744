import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { cssFillHeightByPercentage, cssGlobal, globalTransitionTime } from './css';
import { TTheme, TWithTheme } from '../theme/ThemeContext';
import { Config } from '../config/Config';

export const ThemedBackground = () => {
  return (
    <FullDocumentHeight>
      <LockHeight>
        {_.values(Config.themes).map((theme) => (
          <BackgroundImage
            key={`${theme.id}-background-image`}
            apply={theme}
            style={{ backgroundImage: `url(${theme.backgroundImage})` }}
          />
        ))}
        {_.values(Config.themes).map((theme) => (
          <BackgroundTheme
            key={`${theme.id}-background-color`}
            apply={theme}
          />
        ))}
      </LockHeight>
    </FullDocumentHeight>
  );
};

const FullDocumentHeight = styled.div`
  ${cssGlobal};
  ${cssFillHeightByPercentage};
  position: absolute;
`;

const LockHeight = styled.div`
  ${cssFillHeightByPercentage};
  position: relative;
`;

const LockContent = styled.div`
  -ms-transition: opacity ${globalTransitionTime} ease;
  -webkit-transition: opacity ${globalTransitionTime} ease;
  transition: opacity ${globalTransitionTime} ease;
  
  ${cssFillHeightByPercentage};
  display: flex;
  flex: 1;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

type TApplyThemeProps = {
  apply: TTheme;
};

const BackgroundImage = styled(LockContent)<TApplyThemeProps>`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${({ theme, apply }: TWithTheme<TApplyThemeProps>) => (theme.id === apply.id ? 1 : 0)};
`;

const BackgroundTheme = styled(LockContent)<TApplyThemeProps>`
  background: ${({ apply }: TApplyThemeProps) => apply.background};
  opacity: ${({ theme, apply }: TWithTheme<TApplyThemeProps>) => (theme.id === apply.id ? 1 : 0)};
`;
