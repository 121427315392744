import * as React from 'react';
import styled from 'styled-components';
import { LottieFacebook, LottieGithub, LottieInstagram, LottieLinkedin, LottieTelegram, LottieTwitter } from './Lottie';
import { LocaleContext } from '../model/locale/LocaleContext';

export const SocialBar = () => {
  const { strings } = React.useContext(LocaleContext);
  return (
    <Root>
      {strings.socialInstagram && (
        <IconWrapper target="_blank" href={strings.socialInstagram}>
          <StyledLottieInstagram/>
        </IconWrapper>
      )}
      {strings.socialLinkedin && (
        <IconWrapper target="_blank" href={strings.socialLinkedin}>
          <StyledLottieLinkedin/>
        </IconWrapper>
      )}
      {strings.socialTelegram && (
        <IconWrapper target="_blank" href={strings.socialTelegram}>
          <StyledLottieTelegram/>
        </IconWrapper>
      )}
      {strings.socialTwitter && (
        <IconWrapper target="_blank" href={strings.socialTwitter}>
          <StyledLottieTwitter/>
        </IconWrapper>
      )}
      {strings.socialFacebook && (
        <IconWrapper target="_blank" href={strings.socialFacebook}>
          <StyledLottieFacebook/>
        </IconWrapper>
      )}
      {strings.socialGithub && (
        <IconWrapper target="_blank" href={strings.socialGithub}>
          <StyledLottieGithub/>
        </IconWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function styleSocialIcon(Comp: React.FC<any>): React.FC {
  return styled(Comp)`
    margin-left: -10px;
    margin-right: 10px;
    width: 50px;
  `;
}

const StyledLottieInstagram = styleSocialIcon(LottieInstagram);
const StyledLottieLinkedin = styleSocialIcon(LottieLinkedin);
const StyledLottieTelegram = styleSocialIcon(LottieTelegram);
const StyledLottieTwitter = styleSocialIcon(LottieTwitter);
const StyledLottieFacebook = styleSocialIcon(LottieFacebook);
const StyledLottieGithub = styled(styleSocialIcon(LottieGithub))`
  width: 32px;
  margin-top: -7px;
  margin-left: -5px;
`;
