import { PackageSecrets } from '../instance/PackageSecrets';
import { Utils } from '../../../../../mono/packages/shared-core/src/Utils';

const imageBackgroundOpacity = 0.6;

export const Config = {

  get backendBaseUrl() {
    return PackageSecrets.val.BACKEND_BASE_URL;
  },

  get posthogApiKey() {
    return PackageSecrets.val.POSTHOG_API_KEY;
  },

  get posthogProjectId() {
    return PackageSecrets.val.POSTHOG_PROJECT_ID;
  },

  // The minimum time before the backends "typing" message appears
  messagePreBackendTypingDelayMs: 500,

  // The minimum time before the backends response is posted
  messagePreBackendResponseDelayMs: 300 + 800,

  // Default quick actions in case useDialogFlowQuickResponses returns none
  defaultQuickResponseContext: 'default' as const,

  themes: {
    start: (() => {
      const Palette = {
        background1: '#000000',
        background2: '#000000',
        primaryText: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.8)',
        accent1: '#DE541E',
        accent1Light: '#DE541E',
        accent1Lighter: '#DE541E',
        accent2: '#63CCCA',
        positive: '#87A330',
        chatHeadLeft: 'rgba(0, 0, 0, 0.6)',
        chatHeadRight: Utils.hexToRGB('#87A330', 0.6),
      };
      return {
        id: 'start',
        colors: Palette,
        backgroundImage: require('../assets/background-default.jpg'),
        background: `linear-gradient(120deg, ${Utils.hexToRGB(Palette.background1, imageBackgroundOpacity)}, ${Utils.hexToRGB(Palette.background2, imageBackgroundOpacity)})`,
      };
    })(),

    trainer: (() => {
      const Palette = {
        background1: '#000000',
        background2: '#000000',
        primaryText: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.8)',
        accent1: '#8B2635',
        accent1Light: '#FF6B6C',
        accent1Lighter: '#FE5F55',
        accent2: '#16BAC5',
        positive: '#69B578',
        chatHeadLeft: 'rgba(0, 0, 0, 0.6)',
        chatHeadRight: Utils.hexToRGB('#69B578', 0.6),
      };
      return {
        id: 'trainer',
        colors: Palette,
        backgroundImage: require('../assets/background-trainer.jpg'),
        background: `linear-gradient(120deg, ${Utils.hexToRGB(Palette.background1, imageBackgroundOpacity)}, ${Utils.hexToRGB(Palette.background2, imageBackgroundOpacity)})`,
      };
    })(),

    alacarte: (() => {
      const Palette = {
        background1: '#000000',
        background2: '#000000',
        primaryText: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.8)',
        accent1: '#F15B40',
        accent1Light: '#2B2C33',
        accent1Lighter: '#2B2C33',
        accent2: '#EFB339',
        positive: '#FFDECD',
        chatHeadLeft: 'rgba(200, 200, 200, 0.3)',
        chatHeadRight: Utils.hexToRGB('#F15B40', 0.6),
      };
      return {
        id: 'alacarte',
        colors: Palette,
        backgroundImage: require('../assets/background-alacarte.jpg'),
        background: `linear-gradient(120deg, ${Utils.hexToRGB(Palette.background1, imageBackgroundOpacity)}, ${Utils.hexToRGB(Palette.background2, imageBackgroundOpacity)})`,
      };
    })(),

    work: (() => {
      const Palette = {
        background1: '#000000',
        background2: '#000000',
        primaryText: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.8)',
        accent1: '#DE541E',
        accent1Light: '#DE541E',
        accent1Lighter: '#DE541E',
        accent2: '#63CCCA',
        positive: '#87A330',
        chatHeadLeft: 'rgba(0, 0, 0, 0.6)',
        chatHeadRight: Utils.hexToRGB('#87A330', 0.6),
      };
      return {
        id: 'work',
        colors: Palette,
        backgroundImage: require('../assets/background-work.jpg'),
        background: `linear-gradient(120deg, ${Utils.hexToRGB(Palette.background1, imageBackgroundOpacity)}, ${Utils.hexToRGB(Palette.background2, imageBackgroundOpacity)})`,
      };
    })(),

    projects: (() => {
      const Palette = {
        background1: '#000000',
        background2: '#000000',
        primaryText: '#FFFFFF',
        secondaryText: 'rgba(255, 255, 255, 0.6)',
        accent1: '#DE541E',
        accent1Light: '#DE541E',
        accent1Lighter: '#DE541E',
        accent2: '#63CCCA',
        positive: '#4167ab',
        chatHeadLeft: 'rgba(0, 0, 0, 0.6)',
        chatHeadRight: Utils.hexToRGB('#384E77', 0.6),
      };
      return {
        id: 'projects',
        colors: Palette,
        backgroundImage: require('../assets/background-tech.jpg'),
        background: `linear-gradient(120deg, ${Utils.hexToRGB(Palette.background1, imageBackgroundOpacity)}, ${Utils.hexToRGB(Palette.background2, imageBackgroundOpacity)})`,
      };
    })(),
  },
};

export default Config;
