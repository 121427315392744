import * as React from 'react';
import styled from 'styled-components';

export const SEOLinks = () => {
  return (
    <Root>
      <Link href="https://www.vertexteam.io">Vertex Team</Link>
      <Link href="https://www.alacartemenu.it">à la carte | Digital menu</Link>
      <Link href="https://www.madideas.io">Mad Ideas SRL</Link>
    </Root>
  );
};

const Root = styled.div`
  visibility: hidden;
`;

const Link = styled.a`

`;
