import { createTRPCClient } from '@trpc/client';
import {
  TRPCServer_inferProcedureInput,
  TRPCServer_inferProcedureOutput,
  TTRPCServer_AnyRouter,
  TTRPCServer_ProcedureType,
  TTRPCServer_Router,
} from './TRPCServerInterface';
import { CreateTRPCClientOptions } from '@trpc/client/src/internals/TRPCClient';
import { RemoveStringPrefix, StringKeys } from '../Types';
import { Log } from '../instance/Log';

type TPrefixQuery<T extends string> = `query.${T}`;
type TUnPrefixQuery<T extends string> = RemoveStringPrefix<T, 'query.'>;
type TPrefixMutation<T extends string> = `mutation.${T}`;
type TUnPrefixMutation<T extends string> = RemoveStringPrefix<T, 'mutation.'>;

export type TTRPCStatusEndpointResult = {
  appName: string;
  version: string;
  COMMIT_HASH: string;
}

type TTRPCResolverParams<Input, Ctx> = {
  ctx: Ctx;
  input: Input;
  type: TTRPCServer_ProcedureType;
}

type TTRPCResolverInputAdditionalParams<Input, Ctx> = TTRPCResolverParams<Input, Ctx> & {
  path: string
}

type TTRPCQueryInput<Router extends TTRPCServer_AnyRouter, K extends string> = TRPCServer_inferProcedureInput<Router['_def']['queries'][K]>;
type TTRPCQueryOutput<Router extends TTRPCServer_AnyRouter, K extends string> = Promise<TRPCServer_inferProcedureOutput<Router['_def']['queries'][K]>>;
type TTRPCMutationInput<Router extends TTRPCServer_AnyRouter, K extends string> = TRPCServer_inferProcedureInput<Router['_def']['mutations'][K]>;
type TTRPCMutationOutput<Router extends TTRPCServer_AnyRouter, K extends string> = Promise<TRPCServer_inferProcedureOutput<Router['_def']['mutations'][K]>>;

export type TBuildQueryResolver<Router extends TRPC<any>, K extends string, Ctx> = (params: TTRPCResolverParams<TTRPCQueryInput<Router['_router'], K>, Ctx>) => TTRPCQueryOutput<Router['_router'], K>
export type TBuildMutationResolver<Router extends TRPC<any>, K extends string, Ctx> = (params: TTRPCResolverParams<TTRPCMutationInput<Router['_router'], K>, Ctx>) => TTRPCMutationOutput<Router['_router'], K>

type TTRPCCreateServerParams<Router extends TTRPCServer_AnyRouter, Ctx> = {
  createRouter: TTRPCServer_Router;
  resolvers: {
    [K in TPrefixQuery<StringKeys<Router['_def']['queries']>>]: TBuildQueryResolver<any, TUnPrefixQuery<K>, Ctx>
  } & {
    [K in TPrefixMutation<StringKeys<Router['_def']['mutations']>>]: TBuildMutationResolver<any, TUnPrefixMutation<K>, Ctx>
  }
}

type TTRPCDefineServerParams = {
  createRouter: TTRPCServer_Router;
  createResolver: <TOutput>() => ((params: TTRPCResolverParams<any, any>) => Promise<TOutput>)
}

type TTRPCParams<Router extends TTRPCServer_AnyRouter> = {
  defineServer: (params: TTRPCDefineServerParams) => Router
}

export class TRPC<Router extends TTRPCServer_AnyRouter> {
  // Only use for type access
  readonly _router: Router = null as any;

  private readonly params: TTRPCParams<Router>;

  constructor(params: TTRPCParams<Router>) {
    this.params = params;
  }

  readonly createClient = (opts: CreateTRPCClientOptions<Router>) => {
    return createTRPCClient<Router>(opts);
  };

  readonly createRouter = <Ctx>(opts: TTRPCCreateServerParams<Router, Ctx>) => {
    return this.params.defineServer({
      createRouter: opts.createRouter,
      createResolver: function <TOutput>() {
        return (async function (params: TTRPCResolverInputAdditionalParams<any, Ctx>): Promise<TOutput> {
          const resolverPath = `${params.type}.${params.path}`;
          Log.v("TRPC", "createRouter", `${resolverPath} starting`)
          const resolver = opts.resolvers[resolverPath];
          if (!resolver) {
            throw 404;
          }

          try {
            Log.v("TRPC", "createRouter", `${resolverPath} running resolver`)
            return await resolver(params) as Promise<TOutput>;
          } catch (e) {
            Log.v("TRPC", "createRouter", `${resolverPath} error, ${e.message}`)
            throw e;
          }

        }) as (params: TTRPCResolverParams<any, Ctx>) => Promise<TOutput>;
      },
    });
  };
}
