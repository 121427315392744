import Posthog from 'posthog-js';
import { Config } from '../config/Config';
import { TUser } from '../model/user/User';

class AnalyticsBase {
  constructor() {
    Posthog.init(Config.posthogApiKey, { api_host: 'https://app.posthog.com' });
  }

  readonly identify = (user: TUser) => {
    return Posthog.identify(
      user.id,
      {
        name: user.name,
        type: user.locale,
      },
    );
  };

  readonly reset = () => {
    return Posthog.reset();
  };
}

export const Analytics = new AnalyticsBase();
