import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { cssFillHeightByPercentage, cssGlobal } from './components/css';
import { Header } from './components/Header';
import {
  ChatViewMessageBoxAnimationContext,
  ChatViewMessageBoxAnimationContextProvider,
} from './components/chat/ChatViewMessageBoxAnimationContext';
import { KeyboardContext, KeyboardContextProvider } from './components/chat/KeyboardContext';
import { appPortal, backgroundsPortal } from './lib/HTMLComp';
import { ThemeContextProvider } from './theme/ThemeContext';
import { GlobalContextProvider } from './components/GlobalContext';
import { VisitorContextProvider } from './model/user/VisitorContext';
import { LocaleContextProvider } from './model/locale/LocaleContext';
import { DialogFlowContextProvider } from './model/dialogFlow/DialogFlowContext';
import { ChatContextProvider } from './components/chat/ChatContext';
import { MessageContextProvider } from './model/message/MessageContext';
import { ThemedBackground } from './components/ThemedBackground';
import { CalendarModal } from './components/CalendarModal';
import { SEOLinks } from './components/SEOLinks';
import { Chat } from './components/chat/Chat';
import { WindowContextProvider } from '../../../../mono/packages/lib-react/src/contexts-browser/WindowContext';

const isPreRendering = navigator.userAgent == 'ReactSnap';

export const App = () => {
  return (
    <>
      {!isPreRendering && (
        <WindowContextProvider window={window}>
          <ThemeContextProvider>
            <GlobalContextProvider>
              <KeyboardContextProvider>
                <VisitorContextProvider>
                  <LocaleContextProvider>
                    <MessageContextProvider>
                      <DialogFlowContextProvider>
                        <ChatViewMessageBoxAnimationContextProvider>
                          <ChatContextProvider>
                            <RootView>
                              {ReactDOM.createPortal((<ThemedBackground/>), backgroundsPortal)}
                              {ReactDOM.createPortal((<AppRoot/>), appPortal)}
                              <CalendarModal/>
                            </RootView>
                          </ChatContextProvider>
                        </ChatViewMessageBoxAnimationContextProvider>
                      </DialogFlowContextProvider>
                    </MessageContextProvider>
                  </LocaleContextProvider>
                </VisitorContextProvider>
              </KeyboardContextProvider>
            </GlobalContextProvider>
          </ThemeContextProvider>
        </WindowContextProvider>
      )}
      <SEOLinks/>
    </>
  );
};

export const AppRoot = () => {
  return (
    <Fixed>
      <ScrollableExternal>
        <StyledHeader/>
        <ChatContainer>
          <Chat/>
        </ChatContainer>
      </ScrollableExternal>
    </Fixed>
  );
};

const RootView = styled.div`
  ${cssGlobal};
  ${cssFillHeightByPercentage};
  overflow: hidden;
`;

const Fixed = styled.div`
  ${cssGlobal};
  ${cssFillHeightByPercentage};
  display: flex;
  flex: 1;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ScrollableExternal = ({ children }: PropsWithChildren) => {
  const { chatListScroller } = React.useContext(ChatViewMessageBoxAnimationContext);
  const { keyboardOpen } = React.useContext(KeyboardContext);
  return (
    <Scroll
      keyboardOpen={keyboardOpen}
    >
      <ScrollColumnNormalizer
        ref={chatListScroller?.scrollableRefExternal}
      >
        {children}
      </ScrollColumnNormalizer>
    </Scroll>
  );
};

const Scroll = styled.div<{ keyboardOpen: boolean }>`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  ${({ keyboardOpen }) => (keyboardOpen ? `
    overflow: auto;
    flex-direction: column-reverse;
  ` : `
  `)};
`;

const ScrollColumnNormalizer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const leftRightPadding = '3vw';

const StyledHeader = styled(Header)`
  margin-top: ${leftRightPadding};
  padding: 0 ${leftRightPadding};
`;

const ChatContainer = styled.div`
  flex: 1;
  padding: 12px ${leftRightPadding};
`;
