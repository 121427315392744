import * as React from 'react';
import lottieUnderlineJSON from '../assets/lottie-underline.json';
import lottieCircleJSON from '../assets/lottie-circle.json';
import JSONDownloadFile from '../assets/78158-purple-download-file.json';
import JSONTypingMessage from '../assets/77361-typing-message.json';
import JSONLinkedin from '../assets/49413-linkedin-icon.json';
import JSONTelegram from '../assets/49415-telegram-icon.json';
import JSONInstagram from '../assets/49410-instagram-icon.json';
import JSONTwitter from '../assets/49409-twitter-icon.json';
import JSONFacebook from '../assets/49408-facebook-icon.json';
import JSONGithub from '../assets/81356-github-icon.json';
import JSONClose from '../assets/65846-close-bubble-bottom-sheet.json';
import { StyledProps } from 'styled-components';
import Lottie from 'lottie-react';
import { colorify } from 'lottie-colorify';

type TWithStyles = {
  className?: string;
  style?: StyledProps<any>;
};

type TWithColorableStyles = TWithStyles & {
  color?: Array<string>;
};

export const LottieUnderline = ({ className, style, color }: TWithColorableStyles) => {
  const animationData = React.useMemo(() => {
    return color ? colorify(color, lottieUnderlineJSON) : lottieUnderlineJSON;
  }, [lottieUnderlineJSON, color]);
  return <Lottie loop={false} style={style} className={className} animationData={animationData}/>;
};

export const LottieCircle = ({ className, style, color }: TWithColorableStyles) => {
  const animationData = React.useMemo(() => {
    return color ? colorify(color, lottieCircleJSON) : lottieCircleJSON;
  }, [lottieCircleJSON, color]);
  return <Lottie loop={true}  style={style} className={className} animationData={animationData}/>;
};

export const LottieClose = ({ className, style, color }: TWithColorableStyles) => {
  const animationData = React.useMemo(() => {
    return color ? colorify(color, JSONClose) : JSONClose;
  }, [JSONClose, color]);
  return <Lottie loop={false} style={style} className={className} animationData={animationData}/>;
};

export const LottieTypingMessage = ({ className, style, color }: TWithColorableStyles) => {
  const animationData = React.useMemo(() => {
    return color ? colorify(color, JSONTypingMessage) : JSONTypingMessage;
  }, [JSONTypingMessage, color]);
  return <Lottie loop={true} style={style} className={className} animationData={animationData}/>;
};

export const LottieDownloadFile = ({ className, style, color }: TWithColorableStyles) => {
  const animationData = React.useMemo(() => {
    return color ? colorify(color, JSONDownloadFile) : JSONDownloadFile;
  }, [JSONDownloadFile, color]);
  return <Lottie loop={true} style={style} className={className} animationData={animationData}/>;
};

export const LottieLinkedin = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false}  style={style} className={className} animationData={JSONLinkedin}/>;
};

export const LottieTelegram = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false}  style={style} className={className} animationData={JSONTelegram}/>;
};

export const LottieInstagram = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false} style={style} className={className} animationData={JSONInstagram}/>;
};

export const LottieTwitter = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false}  style={style} className={className} animationData={JSONTwitter}/>;
};

export const LottieFacebook = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false}  style={style} className={className} animationData={JSONFacebook}/>;
};

export const LottieGithub = ({ className, style }: TWithStyles) => {
  return <Lottie loop={false}  style={style} className={className} animationData={JSONGithub}/>;
};
