import * as _ from 'lodash';
import { makeMedia } from '../css';

const messageWidth = {
  xs: '70vw',
  sm: '50vw',
  md: '50vw',
  lg: '40vw',
  xl: '40vw',
};

export const ChatConst = {
  messageMediaParams: makeMedia(_.mapValues(messageWidth, (val) => `max-width: ${val};`)),
  messageContentMediaParams: makeMedia(_.mapValues(messageWidth, (val) => `max-width: calc(${val} - 10px);`)),
};
