import * as _ from 'lodash';
import { ESender, TUser } from '../user/User';

export enum EMessageType {
  Text = 'Text',
  Typing = 'Typing',
  NoDisplay = 'NoDisplay',
}

export type TMessageValueMarkdown = {
  type: EMessageType.Text;
  text: string;
};

export type TMessageValueTyping = {
  type: EMessageType.Typing;
};

export type TMessageValueInvalid = {
  type: EMessageType.NoDisplay;
};

export type TMessageUUID = string;

export type TMessageValue =
  TMessageValueTyping
  | TMessageValueMarkdown
  | TMessageValueInvalid;

export type TMessage<Value extends TMessageValue = any> = {
  id: TMessageUUID;
  isoTimestamp: string;
  user: TUser;
  value: Value;
};

export type TMessageWithoutId = Omit<TMessage, 'id'>;
export type TMessages = Record<string, TMessage>;

export function visitorHasSentMessages(messages: TMessages) {
  return !!_.find(messages, (message) => message.user.type === ESender.Visitor);
}

export function messageIsNotFromVisitor(message?: TMessage) {
  return !messageIsFromVisitor(message);
}

export function messageIsFromVisitor(message?: TMessage) {
  return message?.user.type === ESender.Visitor;
}

export const messageSortByTimestamp = (a: TMessage, b: TMessage) => {
  return a.isoTimestamp < b.isoTimestamp ? -1 : 1;
};
