import * as React from 'react';
import * as _ from 'lodash';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { localeDefault } from './ELocale';
import { Strings } from './Strings';
import { VisitorContext } from '../user/VisitorContext';

const localeContextValue = { strings: Strings[localeDefault] };

export const LocaleContext = React.createContext({
  ...localeContextValue,
  localeContextRef: null as any as React.MutableRefObject<typeof localeContextValue>,
});

type TLocaleContextProps = {
  //
};

export const LocaleContextProvider = ({ children }: PropsWithChildren<TLocaleContextProps>) => {
  const { visitor } = React.useContext(VisitorContext);
  const strings = React.useMemo(() => Strings[visitor.locale], [visitor.locale])

  const value = {
    strings,
  };

  const localeContextRef = useSyncedDataRef(value);

  return (
    <LocaleContext.Provider
      value={{
        ...value,
        localeContextRef,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
