import * as React from 'react';
import styled from 'styled-components';
import { ModalContent } from './ModalContent';

type TImageWithModalProps = {
  className?: string;
  [k: string]: any;
};

export const ImageWithModal = ({
  className,
  ...imageProps
}: TImageWithModalProps) => {
  return (
    <ModalContent
      trigger={(
        <PressableImage
          className={className}
          {...imageProps}
        />
      )}
      content={(
        <ModalImage
          src={imageProps.src}
        />
      )}
    />
  );
};

const Image = styled.img`
`;

const ModalImage = styled(Image)`
  border-radius: 64px;
  max-height: min(90vw, 90vh);
  max-width: min(90vw, 90vh);
  object-fit: contain;
`;

const PressableImage = styled(Image)`
  cursor: pointer;
  object-fit: contain;
`;
