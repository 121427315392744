import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef } from '../../../../../../mono/packages/lib-react/src/hooks/useSyncedDataRef';
import { ESender, TUser } from './User';
import { Strings } from '../locale/Strings';
import { localeDefault } from '../locale/ELocale';
import { Analytics } from '../../lib/Analytics';
import { usePersistedState } from '../../../../../../mono/packages/lib-react/src/contexts-browser/usePersistedState';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

const visitorContextValue = {
  visitor: {
    id: Utils.uuidv4(),
    name: Strings[localeDefault].userAnonymousVisitorName,
    locale: Strings[localeDefault].locale,
    type: ESender.Visitor,
  } as TUser,
  setVisitor: (() => ({})) as any as (set: ((visitor: TUser) => TUser)) => void,
};

export const VisitorContext = React.createContext({
  ...visitorContextValue,
  visitorContextRef: null as any as React.MutableRefObject<typeof visitorContextValue>,
});

type TVisitorContextProps = {
  //
};
export const VisitorContextProvider = ({ children }: PropsWithChildren<TVisitorContextProps>) => {
  const [visitor, _setVisitor] = usePersistedState<TUser>('user', visitorContextValue.visitor);

  const setVisitor = React.useCallback((set: (_: TUser) => TUser) => {
    return _setVisitor((oldVisitor) => {
      const newVisitor = set(oldVisitor);
      Analytics.identify(newVisitor);
      return newVisitor;
    });
  }, [_setVisitor]);

  const visitorContextRef = useSyncedDataRef({
    visitor,
    setVisitor,
  });

  return (
    <VisitorContext.Provider
      value={{
        visitorContextRef,
        visitor,
        setVisitor,
      }}
    >
      {children}
    </VisitorContext.Provider>
  );
};
