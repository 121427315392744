import * as _ from 'lodash';

/**
 * No imports or they will cause cyclic-requires
 * Process variables that are not part of secrets
 * ===
 */

type TEnv = 'production' | 'development' | 'test';
const production = /^(prod|production)$/i;
const development = /^(dev|development)$/i;
const test = /^test$/i;

export type TProcessEnv = {
  NODE_ENV: TEnv;
  PROJECT_SECRET_JSON: string;
  COMMIT_HASH: string;
};

class ProcessBase {
  private maybeEnv: Partial<TProcessEnv>;

  readonly env: TProcessEnv = (() => {
    const self = this;
    return {
      get NODE_ENV() {
        return self.maybeEnv.NODE_ENV ?? 'development';
      },
      get COMMIT_HASH() {
        return self.maybeEnv.COMMIT_HASH ?? '';
      },
      get PROJECT_SECRET_JSON() {
        return self.maybeEnv.PROJECT_SECRET_JSON ?? '';
      },
    };
  })();

  constructor() {
    // We can't say this.maybeEnv = process because of the issue below
    // https://github.com/webpack/webpack/issues/2427#issuecomment-342428107
    this.maybeEnv = {
      NODE_ENV: process.env.NODE_ENV as any,
      COMMIT_HASH: process.env.COMMIT_HASH as any,
      PROJECT_SECRET_JSON: process.env.PROJECT_SECRET_JSON as any,
    };
  }

  readonly updateEnv = (newEnv: TProcessEnv) => {
    this.maybeEnv = newEnv;
  };

  readonly isProduction = (val: string = this.env.NODE_ENV) => {
    return production.test(val);
  };

  readonly isDevelopment = (val: string = this.env.NODE_ENV) => {
    return development.test(val);
  };

  readonly isTest = (val: string = this.env.NODE_ENV) => {
    return test.test(val);
  };
}

export const Process = new ProcessBase();

