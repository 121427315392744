import * as React from 'react';
import { HTMLProps } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Element } from 'hast';
import { LottieDownloadFile } from '../Lottie';
import { ThemeContext, TWithTheme } from '../../theme/ThemeContext';
import { Utils } from '../../../../../../mono/packages/shared-core/src/Utils';

type TMessageTypeMarkdownFileProps = HTMLProps<HTMLAnchorElement> & {
  node: Element;
};

export const MessageTypeMarkdownFile = ({
  className,
  node,
  ...props
}: TMessageTypeMarkdownFileProps) => {
  const { theme } = React.useContext(ThemeContext);

  const onClick = React.useCallback(() => {
    window.open(props.href, '_blank');
  }, [props.href]);

  const children = Utils.safe(
    // @ts-ignore
    () => (!_.isString(props.children[0]) ? props.children : `${props.children[0]}`.replace('file:', '').trim()),
    () => props.children,
  );
  return (
    <Root>
      <Content>
        <StyledLottieDownloadFile
          color={[theme.colors.accent2]}
        />
        <Children onClick={onClick}>
          {children}
        </Children>
        <ClickSurface onClick={onClick}/>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 25px;
`;

const StyledLottieDownloadFile = styled(LottieDownloadFile)`
  margin-top: -100px;
  max-width: 250px;
`;

const Children = styled.a`
  color: ${({ theme }: TWithTheme) => theme.colors.accent2};
  font-weight: 500;
`;

const ClickSurface = styled.div`
  cursor: pointer;
  height: 150px;
  margin-top: 20px;
  max-width: 250px;
  position: absolute;
  width: 100%;
`;
