import { StyledProps } from 'styled-components';
import * as React from 'react';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';

export function useTarget<Props extends { style?: StyledProps<any> }>(
  Component: React.FC<Props>,
  id: string,
  styles?: (rect: DOMRect) => Partial<StyledProps<any>>,
) {
  const [position, setPosition] = React.useState<DOMRect | null>(null);

  const Target = React.useMemo(() => (props: Props) => {
    if (!position) {
      return null;
    }

    const compOffset = styles ?? ((rect: DOMRect) => ({
      top: rect.y,
      left: rect.x,
      width: rect.width,
    }));
    return (
      <Component
        {...props}
        style={{
          ...props?.style,
          position: 'absolute',
          ...compOffset(position),
        }}
      />
    );
  }, [position, Component, styles]);

  const measureRef = useSyncedDataRef(() => {
    setPosition(document.getElementById(id)?.getBoundingClientRect() ?? null);
  });

  const destroyRef = useSyncedDataRef(() => {
    setPosition(null);
  });

  return {
    Target,
    measureRef,
    destroyRef,
  };
}
