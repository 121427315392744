import * as React from 'react';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';

export function useMeasure() {
  const measurementRef = React.createRef<HTMLDivElement>();
  const [measurements, setMeasurements] = React.useState<DOMRect | null>(null);
  const setMeasurementsRef = useSyncedDataRef(setMeasurements);

  const measureRef = useSyncedDataRef(() => {
    const element = measurementRef.current;
    if (element) {
      setMeasurementsRef.current(element.getBoundingClientRect());
    }
  });

  React.useEffect(() => {
    measureRef.current();
  }, []);

  return {
    measurementRef,
    measurements,
    measureRef,
  };
}
